import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { array_type, int32_type, obj_type, union_type, lambda_type, unit_type, class_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../React.fs.js";
import * as react from "react";
import { Affix as Affix_1, DatePicker as DatePicker_1, Drawer as Drawer_1, Spin as Spin_1, Image as Image_1, Badge as Badge_1, Popover as Popover_1, Tooltip as Tooltip_1, Statistic as Statistic_1, Button as Button_1 } from "antd";

export class ButtonProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "Disabled", "HtmlType", "Icon", "Loading", "OnClick", "Type", "Shape", "Size", "Href"];
    }
}

export function ButtonProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.ButtonProps", [], ButtonProps, () => [[["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Button(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Button_1, props_1, ...elems);
}

export class StatisticsProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Title", "Value", "Prefix", "Suffix", "ClassName"];
    }
}

export function StatisticsProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.StatisticsProps", [], StatisticsProps, () => [[["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", obj_type]]]);
}

export function Statistic(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Statistic_1, props_1, ...elems);
}

export class TooltipProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DestroyTooltipOnHide", "Title", "Placement"];
    }
}

export function TooltipProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.TooltipProps", [], TooltipProps, () => [[["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]]]);
}

export function Tooltip(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Tooltip_1, props_1, ...elems);
}

export class PopoverProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Content", "Title"];
    }
}

export function PopoverProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.PopoverProps", [], PopoverProps, () => [[["Item", class_type("Fable.React.ReactElement")]], [["Item", class_type("Fable.React.ReactElement")]]]);
}

export function Popover(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Popover_1, props_1, ...elems);
}

export class BadgeProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Dot", "Count", "Offset", "Color", "ShowZero"];
    }
}

export function BadgeProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.BadgeProps", [], BadgeProps, () => [[["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", array_type(int32_type)]], [["Item", string_type]], [["Item", bool_type]]]);
}

export function Badge(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Badge_1, props_1, ...elems);
}

export class ImageProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Alt", "Fallback", "Src", "Width", "Height", "Preview", "Key"];
    }
}

export function ImageProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.ImageProps", [], ImageProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export function Image(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Image_1, props_1, ...elems);
}

export class SpinProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Delay", "Indicator", "Size", "Spinning"];
    }
}

export function SpinProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.SpinProps", [], SpinProps, () => [[["Item", int32_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", bool_type]]]);
}

export function Spin(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Spin_1, props_1, ...elems);
}

export class DrawerProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Width", "Visible", "Title", "MaskClosable", "ClassName", "OnClose"];
    }
}

export function DrawerProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.DrawerProps", [], DrawerProps, () => [[["Item", int32_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

export function Drawer(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Drawer_1, props_1, ...elems);
}

export class DatePickerProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Format", "ShowTime", "Value", "Disabled", "ClassName", "OnChange", "DisabledDate"];
    }
}

export function DatePickerProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.DatePickerProps", [], DatePickerProps, () => [[["Item", string_type]], [["Item", obj_type]], [["Item", class_type("Loop54.Portal.WebUI.Interop.Moment")]], [["Item", bool_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(class_type("Loop54.Portal.WebUI.Interop.Moment"), bool_type)]]]);
}

export function DatePicker(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(DatePicker_1.RangePicker, props_1, ...elems);
}

export class AffixProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OffsetBottom", "OffsetTop", "Target", "OnChange"];
    }
}

export function AffixProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Misc.AffixProps", [], AffixProps, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]]]);
}

export function Affix(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Affix_1, props_1, ...elems);
}

