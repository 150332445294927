import * as react from "react";
import { useApiCall } from "./ApiCall.fs.js";

export function useUserScopeInformation(getUser) {
    const info = react.useState(undefined);
    const getScopeInformation = useApiCall(getUser, (api, arg) => api.GetScopeInformation(arg));
    react.useEffect(() => {
        const pr = getScopeInformation();
        pr.then((scopeInfos) => {
            info[1](scopeInfos);
        });
    }, []);
    return info[0];
}

export const Context = react.createContext(undefined);

