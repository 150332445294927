import { Record, Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, union_type, lambda_type, unit_type, string_type, class_type, bool_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../../Interop/React.fs.js";
import * as react from "react";
import { Switch as Switch_1 } from "antd";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { value } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { int32ToString } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import { Props, Label } from "../Label/Label.fs.js";
import { empty, ofArray } from "../../fable_modules/fable-library-js.4.18.0/List.js";

export class SwitchProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AutoFocus", "Checked", "CheckedChildren", "UnCheckedChildren", "ClassName", "DefaultChecked", "Disabled", "Loading", "Size", "OnChange", "OnClick", "Id"];
    }
}

export function SwitchProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Switch.SwitchProps", [], SwitchProps, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", lambda_type(bool_type, unit_type)]], [["Item", lambda_type(bool_type, unit_type)]], [["Item", string_type]]]);
}

export function Switch(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Switch_1, props_1, ...elems);
}

export class SwitchWithLabelProps extends Record {
    constructor(label, defaultChecked, onChange, id, className) {
        super();
        this.label = label;
        this.defaultChecked = defaultChecked;
        this.onChange = onChange;
        this.id = id;
        this.className = className;
    }
}

export function SwitchWithLabelProps_$reflection() {
    return record_type("Loop54.Portal.WebUI.Components.Switch.SwitchWithLabelProps", [], SwitchWithLabelProps, () => [["label", class_type("Fable.React.ReactElement")], ["defaultChecked", bool_type], ["onChange", lambda_type(bool_type, unit_type)], ["id", string_type], ["className", string_type]]);
}

export const SwitchWithLabel = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Components/Switch/Switch.fs") + "#L") + int32ToString(value(36))) + ";") + "Loop54.Portal.WebUI.Components.Switch.SwitchWithLabelProps", value("SwitchWithLabel"), (props) => {
    const children = [Label(new Props(props.label, props.id)), Switch(ofArray([new SwitchProps(5, [props.defaultChecked]), new SwitchProps(9, [props.onChange]), new SwitchProps(11, [props.id])]), empty())];
    return react.createElement("div", {
        className: props.className,
    }, ...children);
}, undefined, undefined, "Of");

