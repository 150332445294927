import { Union, Record } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { union_type, record_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import OidcAuth from "../../../src/Services/Auth/OidcAuth.js";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { map as map_1, defaultArg, value as value_1 } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { int32ToString } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import * as react from "react";
import { singleton, map, empty, ofArray } from "../../fable_modules/fable-library-js.4.18.0/List.js";
import { NavLink, Link, Redirect, NavProps, Location, withRouter, RouteProps, Route, Switch } from "../../Interop/ReactRouter.fs.js";
import { join, printf, toText } from "../../fable_modules/fable-library-js.4.18.0/String.js";
import { reject } from "../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";

export class Profile extends Record {
    constructor(id, name, email, language) {
        super();
        this.id = id;
        this.name = name;
        this.email = email;
        this.language = language;
    }
}

export function Profile_$reflection() {
    return record_type("Loop54.Portal.WebUI.Services.Auth.Auth.Profile", [], Profile, () => [["id", string_type], ["name", string_type], ["email", string_type], ["language", string_type]]);
}

export class User extends Record {
    constructor(profile, accessToken) {
        super();
        this.profile = profile;
        this.accessToken = accessToken;
    }
}

export function User_$reflection() {
    return record_type("Loop54.Portal.WebUI.Services.Auth.Auth.User", [], User, () => [["profile", Profile_$reflection()], ["accessToken", string_type]]);
}

export const oidcAuth = OidcAuth;

/**
 * Perform a silent signin.
 */
export function signinSilent() {
    const pr = oidcAuth.silentSignin();
    return pr.then((u) => {
        let name, email;
        const accessToken = u.access_token;
        return new User((name = u.profile.name, (email = u.profile.email, new Profile(u.profile.sub, name, email, u.profile.locale))), accessToken);
    });
}

class Flow extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Redirect", "Silent"];
    }
}

function Flow_$reflection() {
    return union_type("Loop54.Portal.WebUI.Services.Auth.Auth.Flow", [], Flow, () => [[], []]);
}

class Action extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Signin", "Signout"];
    }
}

function Action_$reflection() {
    return union_type("Loop54.Portal.WebUI.Services.Auth.Auth.Action", [], Action, () => [[["Item", Flow_$reflection()]], []]);
}

class Stage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Initiation", "Completion"];
    }
}

function Stage_$reflection() {
    return union_type("Loop54.Portal.WebUI.Services.Auth.Auth.Stage", [], Stage, () => [[], []]);
}

class AuthProps extends Record {
    constructor(action, stage) {
        super();
        this.action = action;
        this.stage = stage;
    }
}

function AuthProps_$reflection() {
    return record_type("Loop54.Portal.WebUI.Services.Auth.Auth.AuthProps", [], AuthProps, () => [["action", Action_$reflection()], ["stage", Stage_$reflection()]]);
}

const AuthComponent = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_1("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Services/Auth/Auth.fs") + "#L") + int32ToString(value_1(59))) + ";") + "Loop54.Portal.WebUI.Services.Auth.Auth.AuthProps", value_1("AuthComponent"), (props) => {
    if (props.action.tag === 1) {
        if (props.stage.tag === 1) {
            oidcAuth.completeSignout();
        }
        else {
            oidcAuth.signout();
        }
    }
    else if (props.action.fields[0].tag === 1) {
        if (props.stage.tag === 1) {
            oidcAuth.completeSilentSignin();
        }
        else {
            oidcAuth.silentSignin();
        }
    }
    else if (props.stage.tag === 1) {
        oidcAuth.completeRedirectSignin();
    }
    else {
        oidcAuth.redirectSignin();
    }
    return react.createElement("span", {});
}, undefined, undefined, "Of");

const Router = (() => {
    const routes_1 = ofArray([["signin/redirect", new AuthProps(new Action(0, [new Flow(0, [])]), new Stage(0, []))], ["signin/redirect/complete", new AuthProps(new Action(0, [new Flow(0, [])]), new Stage(1, []))], ["signin/silent", new AuthProps(new Action(0, [new Flow(1, [])]), new Stage(0, []))], ["signin/silent/complete", new AuthProps(new Action(0, [new Flow(1, [])]), new Stage(1, []))], ["signout", new AuthProps(new Action(1, []), new Stage(0, []))], ["signout/complete", new AuthProps(new Action(1, []), new Stage(1, []))]]);
    return FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_1("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Services/Auth/Auth.fs") + "#L") + int32ToString(value_1(81))) + ";") + "Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,System.Object]]", value_1("Router"), (props_1) => {
        let prefix;
        return Switch(empty(), map((prefix = props_1.match.path, (tupledArg) => Route(ofArray([new RouteProps(0, [toText(printf("%s/%s"))(prefix)(tupledArg[0])]), new RouteProps(2, [true]), new RouteProps(4, [(_arg) => AuthComponent(tupledArg[1])])]), empty())), routes_1));
    }, undefined, undefined, "Of");
})();

export const routes = Route(ofArray([new RouteProps(0, ["/auth"]), new RouteProps(3, [Router])]), empty());

export function getCurrentUser() {
    const pr = oidcAuth.getCurrentUser();
    return pr.then((status) => {
        let accessToken, name, email;
        if (status != null) {
            const u = value_1(status);
            return Promise.resolve((accessToken = u.access_token, new User((name = u.profile.name, (email = u.profile.email, new Profile(u.profile.sub, name, email, u.profile.locale))), accessToken)));
        }
        else {
            return reject("Authentication failed!");
        }
    });
}

function signinRedirectComponent(wrappedComponent, children) {
    return withRouter(FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_1("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Services/Auth/Auth.fs") + "#L") + int32ToString(value_1(107))) + ";") + "Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,System.Object]]", value_1("signinRedirectComponent"), (props) => {
        let arg;
        return wrappedComponent(new NavProps(ofArray([new Location(0, ["/auth/signin/redirect"]), new Location(1, [(arg = encodeURIComponent(defaultArg(map_1((location_1) => join("", [location_1.pathname, location_1.search, location_1.hash]), props.location), "/")), toText(printf("?returnPath=%s"))(arg))])])), children);
    }, undefined, undefined, "Of"));
}

export const SigninRedirect = signinRedirectComponent(Redirect, empty());

export const SigninLink = signinRedirectComponent(Link, singleton("Sign in"));

export const SignoutButton = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_1("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Services/Auth/Auth.fs") + "#L") + int32ToString(value_1(118))) + ";") + "Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,System.Object]]", value_1("SignoutButton"), (props) => NavLink(new NavProps(singleton(new Location(0, ["/auth/signout"]))), singleton(react.createElement("button", {}, "Sign out!"))), undefined, undefined, "Of");

