import { Record } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { value } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { int32ToString } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import * as react from "react";

export class Props extends Record {
    constructor(text, htmlFor) {
        super();
        this.text = text;
        this.htmlFor = htmlFor;
    }
}

export function Props_$reflection() {
    return record_type("Loop54.Portal.WebUI.Components.Label.Props", [], Props, () => [["text", class_type("Fable.React.ReactElement")], ["htmlFor", string_type]]);
}

export const Label = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Components/Label/Label.fs") + "#L") + int32ToString(value(12))) + ";") + "Loop54.Portal.WebUI.Components.Label.Props", value("Label"), (props) => react.createElement("label", {
    htmlFor: props.htmlFor,
}, props.text), undefined, undefined, "Of");

