import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { class_type, float64_type, option_type, bool_type, enum_type, int32_type, record_type, array_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { sign, equals, compare, arrayHash } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { sort } from "../fable_modules/fable-library-js.4.18.0/Array.js";
import { compareWith } from "../fable_modules/fable-library-js.4.18.0/Seq.js";
import { compare as compare_1, compareTo } from "../fable_modules/fable-library-js.4.18.0/String.js";

export class SynonymsEntry extends Record {
    constructor(Query, Synonyms) {
        super();
        this.Query = Query;
        this.Synonyms = Synonyms;
    }
}

export function SynonymsEntry_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.SynonymsEntry", [], SynonymsEntry, () => [["Query", string_type], ["Synonyms", array_type(string_type)]]);
}

export class StoreSynonymsResponse extends Record {
    constructor(NewEntry, ReloadStatus) {
        super();
        this.NewEntry = NewEntry;
        this.ReloadStatus = (ReloadStatus | 0);
    }
}

export function StoreSynonymsResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.StoreSynonymsResponse", [], StoreSynonymsResponse, () => [["NewEntry", SynonymsEntry_$reflection()], ["ReloadStatus", enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]])]]);
}

export class RedirectEntry extends Record {
    constructor(Rules, Name, Target, Enabled) {
        super();
        this.Rules = Rules;
        this.Name = Name;
        this.Target = Target;
        this.Enabled = Enabled;
    }
}

export function RedirectEntry_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.RedirectEntry", [], RedirectEntry, () => [["Rules", array_type(string_type)], ["Name", string_type], ["Target", string_type], ["Enabled", bool_type]]);
}

export class RedirectSettingResponse extends Record {
    constructor(Entry, ReloadStatus) {
        super();
        this.Entry = Entry;
        this.ReloadStatus = (ReloadStatus | 0);
    }
}

export function RedirectSettingResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.RedirectSettingResponse", [], RedirectSettingResponse, () => [["Entry", RedirectEntry_$reflection()], ["ReloadStatus", enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]])]]);
}

export class RedirectSettingRequest extends Record {
    constructor(OldRedirect, EditedRedirect) {
        super();
        this.OldRedirect = OldRedirect;
        this.EditedRedirect = EditedRedirect;
    }
}

export function RedirectSettingRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.RedirectSettingRequest", [], RedirectSettingRequest, () => [["OldRedirect", option_type(RedirectEntry_$reflection())], ["EditedRedirect", RedirectEntry_$reflection()]]);
}

export function hashArray(arr) {
    return arrayHash(sort(arr, {
        Compare: compare,
    }));
}

export function sortedCompareTo(arr1, arr2) {
    return compareWith(compare, sort(arr1, {
        Compare: compare,
    }), sort(arr2, {
        Compare: compare,
    }));
}

export class Feature extends Record {
    constructor(AttributeName, Values) {
        super();
        this.AttributeName = AttributeName;
        this.Values = Values;
    }
    Equals(other) {
        let that;
        const this$ = this;
        return (other instanceof Feature) && ((that = other, this$["System.IEquatable`1.Equals2B595"](that)));
    }
    GetHashCode() {
        const this$ = this;
        return arrayHash([this$.AttributeName, hashArray(this$.Values)]) | 0;
    }
    "System.IEquatable`1.Equals2B595"(other) {
        const this$ = this;
        return (other.AttributeName === this$.AttributeName) && (sortedCompareTo(other.Values, this$.Values) === 0);
    }
    CompareTo(other) {
        let that;
        const this$ = this;
        return ((other instanceof Feature) ? ((that = other, this$["System.IComparable`1.CompareTo2B595"](that))) : -1) | 0;
    }
    "System.IComparable`1.CompareTo2B595"(other) {
        const this$ = this;
        const matchValue = compareTo(other.AttributeName, this$.AttributeName) | 0;
        return ((matchValue === 0) ? sortedCompareTo(other.Values, this$.Values) : matchValue) | 0;
    }
}

export function Feature_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.Feature", [], Feature, () => [["AttributeName", string_type], ["Values", array_type(string_type)]]);
}

export class EntityNaturalKeyValue extends Record {
    constructor(Type, ExternalId) {
        super();
        this.Type = Type;
        this.ExternalId = ExternalId;
    }
}

export function EntityNaturalKeyValue_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.EntityNaturalKeyValue", [], EntityNaturalKeyValue, () => [["Type", string_type], ["ExternalId", string_type]]);
}

export class FeatureSet extends Record {
    constructor(Features, Weight, Entity) {
        super();
        this.Features = Features;
        this.Weight = Weight;
        this.Entity = Entity;
    }
    Equals(other) {
        let that;
        const this$ = this;
        return (other instanceof FeatureSet) && ((that = other, this$["System.IEquatable`1.Equals2B595"](that)));
    }
    GetHashCode() {
        const this$ = this;
        return arrayHash([this$.Entity, this$.Weight, hashArray(this$.Features)]) | 0;
    }
    "System.IEquatable`1.Equals2B595"(other) {
        const this$ = this;
        return (equals(other.Entity, this$.Entity) && (other.Weight === this$.Weight)) && (sortedCompareTo(other.Features, this$.Features) === 0);
    }
    CompareTo(other) {
        let that;
        const this$ = this;
        return ((other instanceof FeatureSet) ? ((that = other, this$["System.IComparable`1.CompareTo2B595"](that))) : -1) | 0;
    }
    "System.IComparable`1.CompareTo2B595"(other) {
        const this$ = this;
        const matchValue = compareTo(other.Entity.Type, this$.Entity.Type) | 0;
        if (matchValue === 0) {
            const matchValue_1 = compareTo(other.Entity.ExternalId, this$.Entity.ExternalId) | 0;
            if (matchValue_1 === 0) {
                const matchValue_2 = this$.Weight - other.Weight;
                return ((matchValue_2 === 0) ? sortedCompareTo(other.Features, this$.Features) : ((matchValue_2 < 0) ? -1 : 1)) | 0;
            }
            else {
                return matchValue_1 | 0;
            }
        }
        else {
            return matchValue | 0;
        }
    }
}

export function FeatureSet_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.FeatureSet", [], FeatureSet, () => [["Features", array_type(Feature_$reflection())], ["Weight", float64_type], ["Entity", EntityNaturalKeyValue_$reflection()]]);
}

export class ExperienceRule extends Record {
    constructor(Query, FeatureSets, IsFixed) {
        super();
        this.Query = Query;
        this.FeatureSets = FeatureSets;
        this.IsFixed = IsFixed;
    }
    Equals(other) {
        let that;
        const this$ = this;
        return (other instanceof ExperienceRule) && ((that = other, this$["System.IEquatable`1.Equals2B595"](that)));
    }
    GetHashCode() {
        const this$ = this;
        return arrayHash([this$.Query, this$.IsFixed, hashArray(this$.FeatureSets)]) | 0;
    }
    "System.IEquatable`1.Equals2B595"(other) {
        const this$ = this;
        return ((other.Query === this$.Query) && (other.IsFixed === this$.IsFixed)) && (sortedCompareTo(other.FeatureSets, this$.FeatureSets) === 0);
    }
    CompareTo(other) {
        let that;
        const this$ = this;
        return ((other instanceof ExperienceRule) ? ((that = other, this$["System.IComparable`1.CompareTo2B595"](that))) : -1) | 0;
    }
    "System.IComparable`1.CompareTo2B595"(other) {
        const this$ = this;
        const matchValue = compareTo(other.Query, this$.Query) | 0;
        return ((matchValue === 0) ? ((other.IsFixed === this$.IsFixed) ? sortedCompareTo(other.FeatureSets, this$.FeatureSets) : (this$.IsFixed ? -1 : 1)) : matchValue) | 0;
    }
}

export function ExperienceRule_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRule", [], ExperienceRule, () => [["Query", string_type], ["FeatureSets", array_type(FeatureSet_$reflection())], ["IsFixed", bool_type]]);
}

export class ExperienceRulePutRequest extends Record {
    constructor(EditedExperienceRule, OldExperienceRule) {
        super();
        this.EditedExperienceRule = EditedExperienceRule;
        this.OldExperienceRule = OldExperienceRule;
    }
}

export function ExperienceRulePutRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRulePutRequest", [], ExperienceRulePutRequest, () => [["EditedExperienceRule", ExperienceRule_$reflection()], ["OldExperienceRule", ExperienceRule_$reflection()]]);
}

export class ExperienceRulePostRequest extends Record {
    constructor(ExperienceRule) {
        super();
        this.ExperienceRule = ExperienceRule;
    }
}

export function ExperienceRulePostRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRulePostRequest", [], ExperienceRulePostRequest, () => [["ExperienceRule", ExperienceRule_$reflection()]]);
}

export class ExperienceRuleDeleteRequest extends Record {
    constructor(Query, ControlCode) {
        super();
        this.Query = Query;
        this.ControlCode = ControlCode;
    }
}

export function ExperienceRuleDeleteRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRuleDeleteRequest", [], ExperienceRuleDeleteRequest, () => [["Query", string_type], ["ControlCode", string_type]]);
}

export class ExperienceRuleResponse extends Record {
    constructor(ExperienceRule, ReloadStatus) {
        super();
        this.ExperienceRule = ExperienceRule;
        this.ReloadStatus = (ReloadStatus | 0);
    }
}

export function ExperienceRuleResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRuleResponse", [], ExperienceRuleResponse, () => [["ExperienceRule", ExperienceRule_$reflection()], ["ReloadStatus", enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]])]]);
}

export class ExperienceRecreateMissingEntitiesEntry extends Record {
    constructor(Query, MissingEntities) {
        super();
        this.Query = Query;
        this.MissingEntities = MissingEntities;
    }
}

export function ExperienceRecreateMissingEntitiesEntry_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRecreateMissingEntitiesEntry", [], ExperienceRecreateMissingEntitiesEntry, () => [["Query", string_type], ["MissingEntities", array_type(EntityNaturalKeyValue_$reflection())]]);
}

export class ExperienceRecreateResponse extends Record {
    constructor(OldRules, NewRules, ReloadStatus, MissingEntitiesPerQuery) {
        super();
        this.OldRules = OldRules;
        this.NewRules = NewRules;
        this.ReloadStatus = ReloadStatus;
        this.MissingEntitiesPerQuery = MissingEntitiesPerQuery;
    }
}

export function ExperienceRecreateResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ExperienceRecreateResponse", [], ExperienceRecreateResponse, () => [["OldRules", array_type(ExperienceRule_$reflection())], ["NewRules", array_type(ExperienceRule_$reflection())], ["ReloadStatus", option_type(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]))], ["MissingEntitiesPerQuery", array_type(ExperienceRecreateMissingEntitiesEntry_$reflection())]]);
}

export class CreateFeatureSetRequest extends Record {
    constructor(Entity) {
        super();
        this.Entity = Entity;
    }
}

export function CreateFeatureSetRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.CreateFeatureSetRequest", [], CreateFeatureSetRequest, () => [["Entity", EntityNaturalKeyValue_$reflection()]]);
}

export class BoostRuleProxy extends Record {
    constructor(AttributeName, AttributeValueType, CompareMode, CompareTo, LowerBound, UpperBound, BoostValue, BoostFromAttribute) {
        super();
        this.AttributeName = AttributeName;
        this.AttributeValueType = (AttributeValueType | 0);
        this.CompareMode = CompareMode;
        this.CompareTo = CompareTo;
        this.LowerBound = LowerBound;
        this.UpperBound = UpperBound;
        this.BoostValue = BoostValue;
        this.BoostFromAttribute = BoostFromAttribute;
    }
}

export function BoostRuleProxy_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.BoostRuleProxy", [], BoostRuleProxy, () => [["AttributeName", string_type], ["AttributeValueType", enum_type("Loop54.Portal.Api.Model.EngineSettings.AttributeValueType", int32_type, [["String", 1], ["Boolean", 2], ["Double", 3], ["DateTime", 4]])], ["CompareMode", string_type], ["CompareTo", option_type(string_type)], ["LowerBound", option_type(string_type)], ["UpperBound", option_type(string_type)], ["BoostValue", option_type(float64_type)], ["BoostFromAttribute", option_type(string_type)]]);
}

export class BoostSettingRequest extends Record {
    constructor(OldBoost, NewBoost, CurrentBoosts) {
        super();
        this.OldBoost = OldBoost;
        this.NewBoost = NewBoost;
        this.CurrentBoosts = CurrentBoosts;
    }
}

export function BoostSettingRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.BoostSettingRequest", [], BoostSettingRequest, () => [["OldBoost", option_type(BoostRuleProxy_$reflection())], ["NewBoost", option_type(BoostRuleProxy_$reflection())], ["CurrentBoosts", array_type(BoostRuleProxy_$reflection())]]);
}

export class BoostSettingResponse extends Record {
    constructor(CurrentBoosts, ReloadStatus) {
        super();
        this.CurrentBoosts = CurrentBoosts;
        this.ReloadStatus = (ReloadStatus | 0);
    }
}

export function BoostSettingResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.BoostSettingResponse", [], BoostSettingResponse, () => [["CurrentBoosts", array_type(BoostRuleProxy_$reflection())], ["ReloadStatus", enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]])]]);
}

export class AttributeInfo extends Record {
    constructor(Name, Description, Type, CanBeBoostCondition, CanProvideBoostAmount) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.Type = (Type | 0);
        this.CanBeBoostCondition = CanBeBoostCondition;
        this.CanProvideBoostAmount = CanProvideBoostAmount;
    }
}

export function AttributeInfo_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.AttributeInfo", [], AttributeInfo, () => [["Name", string_type], ["Description", option_type(string_type)], ["Type", enum_type("Loop54.Portal.Api.Model.EngineSettings.AttributeValueType", int32_type, [["String", 1], ["Boolean", 2], ["Double", 3], ["DateTime", 4]])], ["CanBeBoostCondition", bool_type], ["CanProvideBoostAmount", bool_type]]);
}

export class ProductPositionOverride extends Record {
    constructor(Entity, Position, StartTime, EndTime) {
        super();
        this.Entity = Entity;
        this.Position = (Position | 0);
        this.StartTime = StartTime;
        this.EndTime = EndTime;
    }
    Equals(other) {
        let that;
        const this$ = this;
        return (other instanceof ProductPositionOverride) && ((that = other, this$["System.IEquatable`1.Equals2B595"](that)));
    }
    GetHashCode() {
        const this$ = this;
        return arrayHash([this$.Entity, this$.Position, this$.StartTime, this$.EndTime]) | 0;
    }
    "System.IEquatable`1.Equals2B595"(other) {
        const this$ = this;
        return ((equals(other.Entity, this$.Entity) && (other.Position === this$.Position)) && equals(other.StartTime, this$.StartTime)) && equals(other.EndTime, this$.EndTime);
    }
    CompareTo(other) {
        const this$ = this;
        if (other instanceof ProductPositionOverride) {
            const that = other;
            return this$["System.IComparable`1.CompareTo2B595"](that) | 0;
        }
        else {
            throw new Error("cant compare to a different type");
        }
    }
    "System.IComparable`1.CompareTo2B595"(other) {
        const this$ = this;
        const matchValue = sign(this$.Position - other.Position) | 0;
        if (matchValue === 0) {
            const matchValue_1 = compare(this$.EndTime, other.EndTime) | 0;
            if (matchValue_1 === 0) {
                const matchValue_2 = compareTo(this$.Entity.Type, other.Entity.Type) | 0;
                if (matchValue_2 === 0) {
                    const matchValue_3 = compareTo(this$.Entity.ExternalId, other.Entity.ExternalId) | 0;
                    return ((matchValue_3 === 0) ? compare(this$.StartTime, other.StartTime) : matchValue_3) | 0;
                }
                else {
                    return matchValue_2 | 0;
                }
            }
            else {
                return matchValue_1 | 0;
            }
        }
        else {
            return matchValue | 0;
        }
    }
}

export function ProductPositionOverride_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverride", [], ProductPositionOverride, () => [["Entity", EntityNaturalKeyValue_$reflection()], ["Position", int32_type], ["StartTime", option_type(class_type("System.DateTime"))], ["EndTime", option_type(class_type("System.DateTime"))]]);
}

export function ProductPositionOverride_CreateUnbounded(entityType, entityExternalId, position) {
    return new ProductPositionOverride(new EntityNaturalKeyValue(entityType, entityExternalId), position, undefined, undefined);
}

export class ProductPositionOverrideGlobal extends Record {
    constructor(Pin) {
        super();
        this.Pin = Pin;
    }
    get IPin() {
        const this$ = this;
        return this$.Pin;
    }
}

export function ProductPositionOverrideGlobal_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverrideGlobal", [], ProductPositionOverrideGlobal, () => [["Pin", ProductPositionOverride_$reflection()]]);
}

export class ProductPositionOverridePerQuery extends Record {
    constructor(Query, Pin) {
        super();
        this.Query = Query;
        this.Pin = Pin;
    }
    Equals(other) {
        let that;
        const this$ = this;
        return (other instanceof ProductPositionOverridePerQuery) && ((that = other, this$["System.IEquatable`1.Equals2B595"](that)));
    }
    GetHashCode() {
        const this$ = this;
        return arrayHash([this$.Query, this$.Pin]) | 0;
    }
    "System.IEquatable`1.Equals2B595"(other) {
        const this$ = this;
        return ProductPositionOverridePerQuery__EqualsQuery_Z721C83C5(this$, other.Query) && equals(this$.Pin, other.Pin);
    }
    CompareTo(other) {
        const this$ = this;
        if (other instanceof ProductPositionOverridePerQuery) {
            const that = other;
            return this$["System.IComparable`1.CompareTo2B595"](that) | 0;
        }
        else {
            throw new Error("cant compare to a different type");
        }
    }
    "System.IComparable`1.CompareTo2B595"(other) {
        const this$ = this;
        const matchValue = compare_1(this$.Query, other.Query, ProductPositionOverridePerQuery_get_QueryComparison()) | 0;
        return ((matchValue === 0) ? this$.Pin["System.IComparable`1.CompareTo2B595"](other.Pin) : matchValue) | 0;
    }
    get IPin() {
        const this$ = this;
        return this$.Pin;
    }
    EqualsGroup(other) {
        const this$ = this;
        return ProductPositionOverridePerQuery__EqualsQuery_Z721C83C5(this$, other.Query);
    }
}

export function ProductPositionOverridePerQuery_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverridePerQuery", [], ProductPositionOverridePerQuery, () => [["Query", string_type], ["Pin", ProductPositionOverride_$reflection()]]);
}

export function ProductPositionOverridePerQuery_get_QueryComparison() {
    return 5;
}

export function ProductPositionOverridePerQuery__EqualsQuery_Z721C83C5(this$, query) {
    return compare_1(this$.Query, query, ProductPositionOverridePerQuery_get_QueryComparison()) === 0;
}

export class ProductPositionOverridePerNavigation extends Record {
    constructor(NavigationAttribute, NavigationValue, Pin) {
        super();
        this.NavigationAttribute = NavigationAttribute;
        this.NavigationValue = NavigationValue;
        this.Pin = Pin;
    }
    get IPin() {
        const this$ = this;
        return this$.Pin;
    }
    EqualsGroup(other) {
        const this$ = this;
        return ProductPositionOverridePerNavigation__EqualsNavigation_Z384F8060(this$, other.NavigationAttribute, other.NavigationValue);
    }
}

export function ProductPositionOverridePerNavigation_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverridePerNavigation", [], ProductPositionOverridePerNavigation, () => [["NavigationAttribute", string_type], ["NavigationValue", string_type], ["Pin", ProductPositionOverride_$reflection()]]);
}

export function ProductPositionOverridePerNavigation__EqualsNavigation_Z384F8060(this$, attribute, value) {
    if (this$.NavigationAttribute === attribute) {
        if (value === "") {
            return true;
        }
        else {
            return this$.NavigationValue === value;
        }
    }
    else {
        return false;
    }
}

export class ManualRecommendations extends Record {
    constructor(SourceProduct, TargetProducts) {
        super();
        this.SourceProduct = SourceProduct;
        this.TargetProducts = TargetProducts;
    }
}

export function ManualRecommendations_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ManualRecommendations", [], ManualRecommendations, () => [["SourceProduct", EntityNaturalKeyValue_$reflection()], ["TargetProducts", array_type(ProductPositionOverride_$reflection())]]);
}

export class ManualRecommendationsRequest extends Record {
    constructor(ManualRecommendations) {
        super();
        this.ManualRecommendations = ManualRecommendations;
    }
}

export function ManualRecommendationsRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ManualRecommendationsRequest", [], ManualRecommendationsRequest, () => [["ManualRecommendations", ManualRecommendations_$reflection()]]);
}

export class ManualRecommendationsProductsRequest extends Record {
    constructor(Page, Query) {
        super();
        this.Page = (Page | 0);
        this.Query = Query;
    }
}

export function ManualRecommendationsProductsRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ManualRecommendationsProductsRequest", [], ManualRecommendationsProductsRequest, () => [["Page", int32_type], ["Query", string_type]]);
}

export class ProductPositionOverrideAddRequest$1 extends Record {
    constructor(ProductPositionOverride) {
        super();
        this.ProductPositionOverride = ProductPositionOverride;
    }
}

export function ProductPositionOverrideAddRequest$1_$reflection(gen0) {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverrideAddRequest`1", [gen0], ProductPositionOverrideAddRequest$1, () => [["ProductPositionOverride", gen0]]);
}

export class ProductPositionOverrideUpdateRequest$1 extends Record {
    constructor(UpdatedProductPositionOverride, OldProductPositionOverride) {
        super();
        this.UpdatedProductPositionOverride = UpdatedProductPositionOverride;
        this.OldProductPositionOverride = OldProductPositionOverride;
    }
}

export function ProductPositionOverrideUpdateRequest$1_$reflection(gen0) {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverrideUpdateRequest`1", [gen0], ProductPositionOverrideUpdateRequest$1, () => [["UpdatedProductPositionOverride", gen0], ["OldProductPositionOverride", gen0]]);
}

export class ProductPositionOverrideDeleteRequest$1 extends Record {
    constructor(ProductPositionOverrideToDelete) {
        super();
        this.ProductPositionOverrideToDelete = ProductPositionOverrideToDelete;
    }
}

export function ProductPositionOverrideDeleteRequest$1_$reflection(gen0) {
    return record_type("Loop54.Portal.Api.Model.EngineSettings.ProductPositionOverrideDeleteRequest`1", [gen0], ProductPositionOverrideDeleteRequest$1, () => [["ProductPositionOverrideToDelete", gen0]]);
}

