import { toString, FSharpRef, Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, class_type, bool_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { TooltipProps, Tooltip } from "../Interop/Antd/Misc.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { tryParse } from "../fable_modules/fable-library-js.4.18.0/Boolean.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import * as react from "react";

export class ExperimentalFeaturesHook extends Record {
    constructor(isExperimentalFeaturesEnabled, experimentalFeaturesAbility, isDebugInfoEnabled) {
        super();
        this.isExperimentalFeaturesEnabled = isExperimentalFeaturesEnabled;
        this.experimentalFeaturesAbility = experimentalFeaturesAbility;
        this.isDebugInfoEnabled = isDebugInfoEnabled;
    }
}

export function ExperimentalFeaturesHook_$reflection() {
    return record_type("Loop54.Portal.WebUI.Hooks.ExperimentalFeatures.ExperimentalFeaturesHook", [], ExperimentalFeaturesHook, () => [["isExperimentalFeaturesEnabled", class_type("Fable.React.IStateHook`1", [bool_type])], ["experimentalFeaturesAbility", bool_type], ["isDebugInfoEnabled", class_type("Fable.React.IStateHook`1", [bool_type])]]);
}

const switchTooltip = "Enable or disable experimental features. The page will navigate to the Dashboard when the switch is disabled. This switch is only visible to users with a Loop54 email address.";

export function switchLabel(t) {
    return Tooltip(ofArray([new TooltipProps(1, [t.t(switchTooltip)]), new TooltipProps(2, ["left"])]), singleton(t.t("Experimental features")));
}

function retrieveLocalBoolean(key) {
    let matchValue;
    let matchValue_1;
    let outArg = false;
    matchValue_1 = [tryParse((matchValue = localStorage.getItem(key), (matchValue === defaultOf()) ? "" : matchValue), new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue_1[0]) {
        return matchValue_1[1];
    }
    else {
        return false;
    }
}

export function useExperimentalFeatures(experimentalFeaturesAbility) {
    const storedIsExperimentalFeaturesEnabled = retrieveLocalBoolean("isExperimentalFeaturesEnabled");
    const isExperimentalFeaturesEnabled = react.useState(experimentalFeaturesAbility && storedIsExperimentalFeaturesEnabled);
    react.useEffect(() => {
        localStorage.setItem("isExperimentalFeaturesEnabled", toString(isExperimentalFeaturesEnabled[0]));
    }, [isExperimentalFeaturesEnabled[0]]);
    react.useEffect(() => {
        isExperimentalFeaturesEnabled[1](storedIsExperimentalFeaturesEnabled);
    }, [experimentalFeaturesAbility]);
    const storedIsDebugInfoEnabled = retrieveLocalBoolean("isDebugInfoEnabled");
    const isDebugInfoEnabled = react.useState(storedIsDebugInfoEnabled);
    react.useEffect(() => {
        localStorage.setItem("isDebugInfoEnabled", toString(isDebugInfoEnabled[0]));
    }, [isDebugInfoEnabled[0]]);
    return new ExperimentalFeaturesHook(isExperimentalFeaturesEnabled, experimentalFeaturesAbility, isDebugInfoEnabled);
}

export class ExperimentalFeaturesContext extends Record {
    constructor(IsExperimentalFeaturesEnabled, IsDebugInfoEnabled) {
        super();
        this.IsExperimentalFeaturesEnabled = IsExperimentalFeaturesEnabled;
        this.IsDebugInfoEnabled = IsDebugInfoEnabled;
    }
}

export function ExperimentalFeaturesContext_$reflection() {
    return record_type("Loop54.Portal.WebUI.Hooks.ExperimentalFeatures.ExperimentalFeaturesContext", [], ExperimentalFeaturesContext, () => [["IsExperimentalFeaturesEnabled", bool_type], ["IsDebugInfoEnabled", bool_type]]);
}

export const Context = (() => {
    const defaultValue = new ExperimentalFeaturesContext(false, false);
    return react.createContext(defaultValue);
})();

