import { singleton, append, delay, toArray } from "../fable_modules/fable-library-js.4.18.0/Seq.js";

export const ScopeOnly = ["customerId", "storefrontId", "languageCode", "split"];

export const PulseOverview = toArray(delay(() => append(ScopeOnly, delay(() => append(singleton("pulseQuery"), delay(() => append(singleton("pulseAttribute"), delay(() => singleton("pulseValue")))))))));

export const PinsForProduct = toArray(delay(() => append(PulseOverview, delay(() => append(singleton("productExternalId"), delay(() => singleton("productType")))))));

export const ScopeAndDates = toArray(delay(() => append(ScopeOnly, delay(() => append(singleton("startDate"), delay(() => singleton("endDate")))))));

export const ScopeDatesAndMatch = toArray(delay(() => append(ScopeAndDates, delay(() => append(singleton("matchQuery"), delay(() => singleton("matchType")))))));

