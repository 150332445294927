import { siteNavDateArgs, addArgs, createUrlWithPath } from "../../Utils/ApiUtils.fs.js";
import config from "config";
import { unboxAndStringify, fetchAs } from "../../Utils/ApiUtils.fs.js";
import { choose, empty, ofArray, singleton, append } from "../../fable_modules/fable-library-js.4.18.0/List.js";
import { int64 as int64_1, Auto_generateBoxedDecoder_Z6670B51, fromString } from "../../fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { defaultOf, int32ToString, uncurry2 } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import { class_type, bool_type, enum_type, int32_type, array_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { ExtraCoders, CaseStrategy } from "../../fable_modules/Thoth.Json.10.2.0/Types.fs.js";
import { fold } from "../../fable_modules/fable-library-js.4.18.0/Array.js";
import { empty as empty_1 } from "../../fable_modules/Thoth.Json.10.2.0/Extra.fs.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.18.0/Guid.js";
import { add } from "../../fable_modules/fable-library-js.4.18.0/Map.js";
import { int64 } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { reject } from "../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { QueryFilter__get_toArgs } from "../../Loop54.Portal.Api.Model/KpiQueryFilter.fs.js";
import { ScopeInfos_$reflection } from "../../Loop54.Portal.Api.Model/ScopeInfo.fs.js";
import { CategoryAliasData_$reflection, ProductsWithInteractionsResults_$reflection, AllInteractionTypesMainKpiResults_$reflection, TrendingValuesResults_$reflection, RefinementResults_$reflection, PerformanceResults_$reflection, QueryResultSet$1_$reflection, PerDay_$reflection, GroupedMainKpiResults_$reflection } from "../../Loop54.Portal.Api.Model/Kpi.fs.js";
import { singleton as singleton_1, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.18.0/Seq.js";
import { toString } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { map } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { ManualRecommendationsRequest, ManualRecommendations_$reflection, ExperienceRecreateResponse_$reflection, FeatureSet_$reflection, CreateFeatureSetRequest, EntityNaturalKeyValue, ExperienceRuleDeleteRequest, ExperienceRulePostRequest, ExperienceRuleResponse_$reflection, ExperienceRulePutRequest, ExperienceRule_$reflection, AttributeInfo_$reflection, RedirectSettingResponse_$reflection, RedirectSettingRequest, RedirectEntry_$reflection, BoostSettingResponse_$reflection, BoostSettingRequest, BoostRuleProxy_$reflection, StoreSynonymsResponse_$reflection, SynonymsEntry_$reflection } from "../../Loop54.Portal.Api.Model/EngineSettings.fs.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ProductWithProductPositionOverrideResponse_$reflection, ProductQueryRequest, ProductQueryResponse_$reflection, Product_$reflection } from "../../Loop54.Portal.Api.Model/Product.fs.js";
import { PulseRankingsResponse_$reflection } from "../../Loop54.Portal.Api.Model/Pulse.fs.js";
import * as react from "react";

export function endpoint(path) {
    return createUrlWithPath(config.urls.api, path);
}

export class PortalApi {
    constructor() {
    }
    GetFriendlyName(user, filter, navigationType, _arg) {
        const pr = fetchAs(user, addArgs(append(ofArray([["requestType", singleton(_arg[0])], ["requestId", singleton(_arg[1])]]), siteNavDateArgs(filter, navigationType)), endpoint("Kpi/GetFriendlyName")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(string_type, new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetAvailableSplits(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("ScopeInfo/GetAvailableSplits")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(string_type), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetScopeInformation(user) {
        const pr = fetchAs(user, addArgs(empty(), endpoint("ScopeInfo/ScopeInfos")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ScopeInfos_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetMainKpis(user, filter, navigationType) {
        const pr = fetchAs(user, addArgs(siteNavDateArgs(filter, navigationType), endpoint("Kpi/GetMainKpis")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(GroupedMainKpiResults_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetNavigationsPerDay(user, filter, navigationType) {
        const pr = fetchAs(user, addArgs(siteNavDateArgs(filter, navigationType), endpoint("Kpi/NavigationsPerDay")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(PerDay_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetPopularRequests(user, filter, navigationType) {
        const pr = fetchAs(user, addArgs(siteNavDateArgs(filter, navigationType), endpoint("Kpi/PopularRequests")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(PerformanceResults_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetLowPerformingRequests(user, filter, navigationType) {
        const pr = fetchAs(user, addArgs(siteNavDateArgs(filter, navigationType), endpoint("Kpi/LowPerformingRequests")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(PerformanceResults_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetNavigationRefinements(user, filter, navigationType, withDebugDetails, refinementParams) {
        const pr = fetchAs(user, addArgs(toList(delay(() => append_1(siteNavDateArgs(filter, navigationType), delay(() => append_1(singleton_1(["withDebugDetails", singleton(toString(withDebugDetails))]), delay(() => {
            let refineParams;
            return (refineParams = refinementParams, choose((tupledArg) => map((v) => [tupledArg[0], singleton(v.toString())], tupledArg[1]), ofArray([["qrMinSessions", map((value) => value, refineParams.MinSessions)], ["qrMinRefinementRate", refineParams.MinRefinementRate], ["qrMinRefinedIr", refineParams.MinRefinedIr], ["qrMinIrRatio", refineParams.MinIrRatio]])));
        })))))), endpoint("Kpi/NavigationRefinements")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(RefinementResults_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetTrendingRequests(user, filter, navigationType) {
        const pr = fetchAs(user, addArgs(siteNavDateArgs(filter, navigationType), endpoint("Kpi/TrendingRequests")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(TrendingValuesResults_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetMainKpisForMatchingQueries(user, filter, navigationType, specificQuery, matchType) {
        let list_1;
        const pr = fetchAs(user, addArgs((list_1 = siteNavDateArgs(filter, navigationType), append(ofArray([["specificQuery", singleton(specificQuery)], ["matchType", singleton(int32ToString(matchType))]]), list_1)), endpoint("Kpi/GetMainKpisForMatchingQueries")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(GroupedMainKpiResults_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetMatchingQueries(user, filter, navigationType, specificQuery, matchType) {
        let list_1;
        const pr = fetchAs(user, addArgs((list_1 = siteNavDateArgs(filter, navigationType), append(ofArray([["specificQuery", singleton(specificQuery)], ["matchType", singleton(int32ToString(matchType))]]), list_1)), endpoint("Kpi/GetMatchingQueries")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(PerformanceResults_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetNavigationsPerDayForMatchingQueries(user, filter, navigationType, specificQuery, matchType) {
        let list_1;
        const pr = fetchAs(user, addArgs((list_1 = siteNavDateArgs(filter, navigationType), append(ofArray([["specificQuery", singleton(specificQuery)], ["matchType", singleton(int32ToString(matchType))]]), list_1)), endpoint("Kpi/NavigationsPerDay")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(PerDay_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetAllInteractionTypesMainKpis(user, filter, navigationType, specificQuery) {
        const pr = fetchAs(user, addArgs(append(singleton(["specificQuery", singleton(specificQuery)]), siteNavDateArgs(filter, navigationType)), endpoint("Kpi/GetAllInteractionTypesMainKpis")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(AllInteractionTypesMainKpiResults_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetProductsWithInteractions(user, filter, navigationType, specificAssociatedQuery) {
        const pr = fetchAs(user, addArgs(append(singleton(["specificAssociatedQuery", singleton(specificAssociatedQuery)]), siteNavDateArgs(filter, navigationType)), endpoint("Kpi/ProductsWithInteractions")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(QueryResultSet$1_$reflection(ProductsWithInteractionsResults_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetSynonyms(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("EngineSettings/SynonymsForScope")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(SynonymsEntry_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutSynonyms(user, filter, oldSynonymsEntry, newSynonymsEntry) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["oldQuery", singleton(oldSynonymsEntry.Query)], ["newQuery", singleton(newSynonymsEntry.Query)], ["oldSynonyms", ofArray(oldSynonymsEntry.Synonyms)], ["newSynonyms", ofArray(newSynonymsEntry.Synonyms)]])), endpoint("EngineSettings/SynonymsForQuery")), empty(), singleton(new Types_RequestProperties(0, ["PUT"])));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(StoreSynonymsResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteSynonyms(user, filter, synonymsEntry) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["oldQuery", singleton(synonymsEntry.Query)], ["oldSynonyms", ofArray(synonymsEntry.Synonyms)]])), endpoint("EngineSettings/SynonymsForQuery")), empty(), singleton(new Types_RequestProperties(0, ["DELETE"])));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetBoosts(user, filter, boostBuryQuest) {
        const tupledArg = [filter, boostBuryQuest];
        const pr = ((url_2) => fetchAs(user, url_2, empty(), empty()))(addArgs(append(QueryFilter__get_toArgs(tupledArg[0]), singleton(["quest", singleton(int32ToString(tupledArg[1]))])), endpoint("BoostBury/BoostsForScope")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(BoostRuleProxy_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutBoosts(user, filter, boostBuryQuest, oldBoost, newBoost, currentBoosts) {
        const request = new BoostSettingRequest(oldBoost, newBoost, currentBoosts);
        const tupledArg = [filter, boostBuryQuest];
        const pr = ((user_1) => ((requestBody) => ((url_2) => fetchAs(user_1, url_2, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(append(QueryFilter__get_toArgs(tupledArg[0]), singleton(["quest", singleton(int32ToString(tupledArg[1]))])), endpoint("BoostBury/BoostsForScope")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(BoostSettingResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteBoosts(user, filter, boostBuryQuest, oldBoost, currentBoosts) {
        const request = new BoostSettingRequest(oldBoost, undefined, currentBoosts);
        const tupledArg = [filter, boostBuryQuest];
        const pr = ((user_1) => ((requestBody) => ((url_2) => fetchAs(user_1, url_2, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PATCH"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(append(QueryFilter__get_toArgs(tupledArg[0]), singleton(["quest", singleton(int32ToString(tupledArg[1]))])), endpoint("BoostBury/BoostsForScope")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(BoostSettingResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetRedirect(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("EngineSettings/Redirect")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(RedirectEntry_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutRedirect(user, filter, oldRedirect, newRedirect) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new RedirectSettingRequest(oldRedirect, newRedirect);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("EngineSettings/Redirect")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(RedirectSettingResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteRedirect(user, filter, redirectEntry) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["enabled", singleton(toString(redirectEntry.Enabled))], ["name", singleton(redirectEntry.Name)], ["target", singleton(redirectEntry.Target)], ["rules", ofArray(redirectEntry.Rules)]])), endpoint("EngineSettings/Redirect")), empty(), singleton(new Types_RequestProperties(0, ["DELETE"])));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    AvailableAttributes(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("BoostBury/AvailableAttributes")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(AttributeInfo_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetExperienceRules(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("Experience/Rules")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(ExperienceRule_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    CheckExperienceRuleExist(user, filter, query) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), singleton(["query", singleton(query)])), endpoint("Experience/RuleById")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(bool_type, new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetExperienceRule(user, filter, query) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), singleton(["query", singleton(query)])), endpoint("Experience/RuleById")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ExperienceRule_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutExperienceRule(user, filter, oldExperienceRule, editedExperienceRule) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new ExperienceRulePutRequest(editedExperienceRule, oldExperienceRule);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Experience/Rule")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ExperienceRuleResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PostExperienceRule(user, filter, experienceRule) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new ExperienceRulePostRequest(experienceRule);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Experience/Rule")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ExperienceRuleResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteExperienceRule(user, filter, rule) {
        let url;
        const pr_1 = ((user_1) => ((requestBody) => ((url_1) => fetchAs(user_1, url_1, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(new ExperienceRulePostRequest(rule))((url = endpoint("Experience/ControlCode"), addArgs(QueryFilter__get_toArgs(filter), url)));
        return pr_1.then((controlCode) => {
            const args_1 = QueryFilter__get_toArgs(filter);
            const req = new ExperienceRuleDeleteRequest(rule.Query, controlCode);
            const pr = ((user_3) => ((requestBody_2) => ((url_3) => fetchAs(user_3, url_3, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody_2)])])))))(user)(req)(addArgs(args_1, endpoint("Experience/Rule")));
            return pr.then((json) => {
                const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                    let copyOfStruct;
                    return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
                }]))), json);
                if (result_1.tag === 1) {
                    return reject(new Error(result_1.fields[0]));
                }
                else {
                    return Promise.resolve(result_1.fields[0]);
                }
            });
        });
    }
    CreateFeatureSet(user, filter, experienceProduct) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new CreateFeatureSetRequest(new EntityNaturalKeyValue(experienceProduct.Entity.Type, experienceProduct.Entity.ExternalId));
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Experience/CreateFeatureSet")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(FeatureSet_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetFeatureSetProducts(user, filter, entityType, entityExternalId) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["entityType", singleton(entityType)], ["entityExternalId", singleton(entityExternalId)]])), endpoint("Experience/FeatureSetProducts")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(Product_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    RecreateFeatureSets(user, filter, preview) {
        const args = append(QueryFilter__get_toArgs(filter), singleton(["preview", toList(delay(() => (preview ? singleton_1("true") : singleton_1("false"))))]));
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(defaultOf())(addArgs(args, endpoint("Experience/RecreateFeatureSets")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ExperienceRecreateResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    SearchProducts(user, filter, query, page) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["query", singleton(query)], ["page", singleton(int32ToString(page))]])), endpoint("Experience/Preview/Search")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ProductQueryResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PreviewProducts(user, filter, experienceRule, page) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new ProductQueryRequest(experienceRule, page);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Experience/Preview/Preview")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ProductQueryResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetComplementaryEntities(user, filter, product) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["entityType", singleton(product.Entity.Type)], ["entityExternalId", singleton(product.Entity.ExternalId)]])), endpoint("Experience/Preview/ComplementaryEntities")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(Product_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetManualRecommendations(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("ManualRecommendation/Get")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(ManualRecommendations_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PostManualRecommendation(user, filter, manualRecommendations) {
        const args = QueryFilter__get_toArgs(filter);
        const request = new ManualRecommendationsRequest(manualRecommendations);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("ManualRecommendation/Add")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetManualRecommendationsProducts(user, filter, query, page) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["query", singleton(query)], ["page", singleton(int32ToString(page))]])), endpoint("ManualRecommendation/GetManualRecommendationProducts")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ProductQueryResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetProductPositionOverrides(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("Pulse/GetProductPositionOverridesWithProducts")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ProductWithProductPositionOverrideResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PostProductPositionOverrideGlobal(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/AddProductPositionOverrideGlobal")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PostProductPositionOverridePerQuery(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/AddProductPositionOverridePerQuery")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PostProductPositionOverridePerNavigation(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/AddProductPositionOverridePerNavigation")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutProductPositionOverrideGlobal(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/UpdateProductPositionOverrideGlobal")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutProductPositionOverridePerQuery(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/UpdateProductPositionOverridePerQuery")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PutProductPositionOverridePerNavigation(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/UpdateProductPositionOverridePerNavigation")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteProductPositionOverrideGlobal(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/DeleteProductPositionOverrideGlobal")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteProductPositionOverridePerQuery(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/DeleteProductPositionOverridePerQuery")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    DeleteProductPositionOverridePerNavigation(user, filter, request) {
        const args = QueryFilter__get_toArgs(filter);
        const pr = ((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(user)(request)(addArgs(args, endpoint("Pulse/DeleteProductPositionOverridePerNavigation")));
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(enum_type("Loop54.Portal.Api.Model.EngineSettings.ReloadSettingsStatus", int32_type, [["NoOnlineEngines", 1], ["AllSucceeded", 2], ["SomeMayHaveFailed", 3], ["Error", 4]]), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PulseRankingsGlobal(user, filter, topRankedCount) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), singleton(["topRankedCount", singleton(int32ToString(topRankedCount))])), endpoint("Pulse/PulseRankingsGlobal")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(PulseRankingsResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PulseRankingsPerQuery(user, filter, query, page) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["page", singleton(int32ToString(page))], ["query", singleton(query)]])), endpoint("Pulse/PulseRankingsPerQuery")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(PulseRankingsResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PulseRankingsPerNavigation(user, filter, navigationAttribute, navigationValue, page) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["page", singleton(int32ToString(page))], ["navigationAttribute", singleton(navigationAttribute)], ["navigationValue", singleton(navigationValue)]])), endpoint("Pulse/PulseRankingsPerNavigation")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(PulseRankingsResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    PulseRankingForProduct(user, filter, productExternalId, productType) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["productExternalId", singleton(productExternalId)], ["productType", singleton(productType)]])), endpoint("Pulse/PulseRankingForProduct")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(PulseRankingsResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetNavigationValues(user, filter, attributeName) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), singleton(["attributeName", singleton(attributeName)])), endpoint("Pulse/GetPinNavigationValues")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(string_type), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetRequestAliases(user, filter) {
        const pr = fetchAs(user, addArgs(QueryFilter__get_toArgs(filter), endpoint("Pulse/GetRequestAliases")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(array_type(CategoryAliasData_$reflection()), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
    GetProductPositionOverridesForProduct(user, filter, productExternalId, productType) {
        const pr = fetchAs(user, addArgs(append(QueryFilter__get_toArgs(filter), ofArray([["productExternalId", singleton(productExternalId)], ["productType", singleton(productType)]])), endpoint("Pulse/GetProductPositionOverridesForProduct")), empty(), empty());
        return pr.then((json) => {
            const result_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(ProductWithProductPositionOverrideResponse_$reflection(), new CaseStrategy(1, []), fold((arg, func) => func(arg), empty_1, [(extra) => {
                let copyOfStruct;
                return new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Int64", [int64, int64_1], extra.Coders));
            }]))), json);
            if (result_1.tag === 1) {
                return reject(new Error(result_1.fields[0]));
            }
            else {
                return Promise.resolve(result_1.fields[0]);
            }
        });
    }
}

export function PortalApi_$reflection() {
    return class_type("Loop54.Portal.WebUI.Services.Api.Api.PortalApi", undefined, PortalApi);
}

export function PortalApi_$ctor() {
    return new PortalApi();
}

export const Context = (() => {
    const defaultValue = PortalApi_$ctor();
    return react.createContext(defaultValue);
})();

