import { ScopePartValueModule_fromUrlParam, ScopePartValueModule_toUrlParam } from "../Hooks/ScopeComponentUtils.fs.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.18.0/Option.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.18.0/String.js";

export const ScopePartParam = {
    encode(scopePart) {
        return ScopePartValueModule_toUrlParam(scopePart);
    },
    decode(urlParam) {
        return ScopePartValueModule_fromUrlParam(urlParam);
    },
};

export const CustomerIdParam = {
    encode(customerId) {
        return defaultArg(customerId, "");
    },
    decode(urlParam) {
        return isNullOrWhiteSpace(urlParam) ? undefined : urlParam;
    },
};

