import { useQueryParam } from "./Query.fs.js";
import { ScopePartParam, CustomerIdParam } from "../Interop/CustomQueryParams.fs.js";
import { bind } from "../fable_modules/fable-library-js.4.18.0/Option.js";
import { findBestMatchForScope } from "./ScopeComponentUtils.fs.js";
import * as react from "react";
import { Context } from "./UserScopeInformation.fs.js";

export function useUserAccess(_arg) {
    const patternInput = useQueryParam("customerId", CustomerIdParam);
    const patternInput_1 = useQueryParam("storefrontId", ScopePartParam);
    const patternInput_2 = useQueryParam("languageCode", ScopePartParam);
    return bind((scope) => scope.Access, bind((scopeInfos) => findBestMatchForScope(scopeInfos.AllowedScopes, patternInput[0], patternInput_1[0], patternInput_2[0]), react.useContext(Context)));
}

