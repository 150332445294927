import { class_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { disposeSafe, getEnumerator, createObj, defaultOf } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { getItemFromDict, tryGetValue } from "../fable_modules/fable-library-js.4.18.0/MapUtil.js";
import { FSharpRef } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import * as react from "react";
import { useQueryParam as useQueryParam_1, useQueryParams as useQueryParams_1 } from "use-query-params";
import { map } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { encodeQueryParam } from "../Interop/UseQueryParams.fs.js";
import { defaultArg, some } from "../fable_modules/fable-library-js.4.18.0/Option.js";

export class UrlParamsStoreContext {
    constructor() {
        this.cache = (new Map([]));
    }
}

export function UrlParamsStoreContext_$reflection() {
    return class_type("Loop54.Portal.WebUI.Hooks.Query.UrlParamsStoreContext", undefined, UrlParamsStoreContext);
}

export function UrlParamsStoreContext_$ctor() {
    return new UrlParamsStoreContext();
}

export function UrlParamsStoreContext__TryGet_Z721C83C5(this$, key) {
    let matchValue;
    let outArg = defaultOf();
    matchValue = [tryGetValue(this$.cache, key, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return undefined;
    }
}

export function UrlParamsStoreContext__Set(this$, key, value) {
    this$.cache.set(key, value);
}

export const Context = (() => {
    const defaultValue = UrlParamsStoreContext_$ctor();
    return react.createContext(defaultValue);
})();

export function useQueryParams(queryParams) {
    const ctxt = react.useContext(Context);
    const paramTypes = new Map(queryParams);
    const patternInput = useQueryParams_1(createObj(map((tupledArg) => [tupledArg[0], tupledArg[1]], queryParams)));
    return [patternInput[0], (v_2) => {
        let enumerator = getEnumerator(Object.entries(v_2));
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const entry = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                const key = entry[0];
                UrlParamsStoreContext__Set(ctxt, key, encodeQueryParam(key, getItemFromDict(paramTypes, key), entry[1]));
            }
        }
        finally {
            disposeSafe(enumerator);
        }
        patternInput[1](v_2);
    }];
}

export function useQueryParam(key, paramType) {
    const ctxt = react.useContext(Context);
    const patternInput = useQueryParam_1(key, paramType);
    return [patternInput[0], (v_1) => {
        UrlParamsStoreContext__Set(ctxt, key, encodeQueryParam(key, paramType, v_1));
        patternInput[1](v_1);
    }];
}

export function useQueryParamOption(key, paramType) {
    const patternInput = useQueryParam(key, paramType);
    const v = patternInput[0];
    return [(v == null) ? undefined : some(v), (v_1) => {
        patternInput[1](defaultArg(v_1, void 0));
    }];
}

