import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { bool_type, array_type, record_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";

export class Scope extends Record {
    constructor(Customer, Storefront, LanguageCode) {
        super();
        this.Customer = Customer;
        this.Storefront = Storefront;
        this.LanguageCode = LanguageCode;
    }
}

export function Scope_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Scope", [], Scope, () => [["Customer", IdNameTuple_$reflection()], ["Storefront", IdNameTuple_$reflection()], ["LanguageCode", string_type]]);
}

export class IdNameTuple extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function IdNameTuple_$reflection() {
    return record_type("Loop54.Portal.Api.Model.IdNameTuple", [], IdNameTuple, () => [["Id", string_type], ["Name", string_type]]);
}

export function IdNameTuple_get_Empty() {
    return new IdNameTuple("", "");
}

export class ScopeInfos extends Record {
    constructor(AllowedScopes, DefaultScope) {
        super();
        this.AllowedScopes = AllowedScopes;
        this.DefaultScope = DefaultScope;
    }
}

export function ScopeInfos_$reflection() {
    return record_type("Loop54.Portal.Api.Model.ScopeInfos", [], ScopeInfos, () => [["AllowedScopes", array_type(ScopeInfo_$reflection())], ["DefaultScope", ScopeInfo_$reflection()]]);
}

export class ScopeInfo extends Record {
    constructor(AllowedScope, Access, AvailableScopeComponents) {
        super();
        this.AllowedScope = AllowedScope;
        this.Access = Access;
        this.AvailableScopeComponents = AvailableScopeComponents;
    }
}

export function ScopeInfo_$reflection() {
    return record_type("Loop54.Portal.Api.Model.ScopeInfo", [], ScopeInfo, () => [["AllowedScope", Scope_$reflection()], ["Access", Access_$reflection()], ["AvailableScopeComponents", array_type(StorefrontToLanguages_$reflection())]]);
}

export class Access extends Record {
    constructor(SynonymsAccess, BoostBuryAccess, RedirectsAccess, RefinementsAccess, ExperienceEditorAccess, ManualRecommendationsAccess, PulseAccess, PulseLocalPinsAccess, RecommendationsKpiGreAccess, RecommendationsKpiGceAccess, RecommendationsKpiGreceAccess, RecommendationsKpiGbrAccess) {
        super();
        this.SynonymsAccess = SynonymsAccess;
        this.BoostBuryAccess = BoostBuryAccess;
        this.RedirectsAccess = RedirectsAccess;
        this.RefinementsAccess = RefinementsAccess;
        this.ExperienceEditorAccess = ExperienceEditorAccess;
        this.ManualRecommendationsAccess = ManualRecommendationsAccess;
        this.PulseAccess = PulseAccess;
        this.PulseLocalPinsAccess = PulseLocalPinsAccess;
        this.RecommendationsKpiGreAccess = RecommendationsKpiGreAccess;
        this.RecommendationsKpiGceAccess = RecommendationsKpiGceAccess;
        this.RecommendationsKpiGreceAccess = RecommendationsKpiGreceAccess;
        this.RecommendationsKpiGbrAccess = RecommendationsKpiGbrAccess;
    }
}

export function Access_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Access", [], Access, () => [["SynonymsAccess", bool_type], ["BoostBuryAccess", bool_type], ["RedirectsAccess", bool_type], ["RefinementsAccess", bool_type], ["ExperienceEditorAccess", bool_type], ["ManualRecommendationsAccess", bool_type], ["PulseAccess", bool_type], ["PulseLocalPinsAccess", bool_type], ["RecommendationsKpiGreAccess", bool_type], ["RecommendationsKpiGceAccess", bool_type], ["RecommendationsKpiGreceAccess", bool_type], ["RecommendationsKpiGbrAccess", bool_type]]);
}

export class StorefrontToLanguages extends Record {
    constructor(Storefront, AvailableLanguages) {
        super();
        this.Storefront = Storefront;
        this.AvailableLanguages = AvailableLanguages;
    }
}

export function StorefrontToLanguages_$reflection() {
    return record_type("Loop54.Portal.Api.Model.StorefrontToLanguages", [], StorefrontToLanguages, () => [["Storefront", IdNameTuple_$reflection()], ["AvailableLanguages", array_type(string_type)]]);
}

export function ScopeInfo_get_NotInitialized() {
    return new ScopeInfo(new Scope(IdNameTuple_get_Empty(), IdNameTuple_get_Empty(), ""), Access_get_NotInitialized(), new Array(0));
}

export function Access_get_AllowAll() {
    return new Access(true, true, true, true, true, true, true, true, true, true, true, true);
}

export function Access_get_Default() {
    return new Access(true, true, false, false, false, false, false, false, false, false, false, false);
}

export function Access_get_NotInitialized() {
    return new Access(false, false, false, false, false, false, false, false, false, false, false, false);
}

