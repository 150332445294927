import Oidc from 'oidc-client';

import config from 'config';

const AuthSettings = {
    'SelfOrigin': config.urls.webui,
    'Authority': config.urls.identity,
    'ClientId': config.auth.client_id,
    'Scopes': config.auth.scopes,
    'LoginCallbackUrl': config.urls.webui + '/auth/signin/redirect/complete',
    'SilentLoginCallbackUrl': config.urls.webui + '/auth/signin/silent/complete',
    'LogoutRedirectUrl': config.urls.webui + '/auth/signout/complete',
    'LogoutLandingUrl': config.urls.webui + '/'
};

Oidc.Log.logger = console;
//Oidc.Log.level = Oidc.Log.DEBUG; // Uncomment this to get detailed oidc-client logs - useful for troubleshooting it

/**
 * @private
 * @return {Oidc.UserManager} Returning user manager
 */
function ensureUserManager() {
    if (!this.userManager) {
        const config = {
            authority: AuthSettings.Authority,
            allowedCorsOrigins: AuthSettings.SelfOrigin,
            client_id: AuthSettings.ClientId,
            redirect_uri: AuthSettings.LoginCallbackUrl,
            silent_redirect_uri: AuthSettings.SilentLoginCallbackUrl,

            // This needs to be quite long, because sometimes the iframe-based silent sign-in is attempted as part of a failed API call. This means
            // that for each outstanding API call, there will be an iframe loading the entire full React application just to confirm silent sign-in
            // worked. If this is not given enough time to load, it will show a confusing failure popup despite actually succeeding moments later.
            // This should only be needed rarely, but if it turns out to be often, we should revisit the decision to confirm silent sign-ins this way.
            silentRequestTimeout: 15000, // ms

            // Do not set automaticSilentRenew=true here, as this is used to create multiple UserManager objects
            accessTokenExpiringNotificationTime: 20, // Try to renew access tokens automatically this many seconds before they expire
            response_type: "code",
            scope: AuthSettings.Scopes,
            post_logout_redirect_uri: AuthSettings.LogoutRedirectUrl
        };
        this.userManager = new Oidc.UserManager(config);

        // Automatically renew tokens by means of silent sign in as they near expiration
        this.userManager.startSilentRenew();

        this.userManager.events.addSilentRenewError(e => {
            if (e.error !== 'login_required') {
                console.error('[OidcAuth:SilentRenewError] Silent renewal failed:');
                console.error(e);
            }
        });

        // Generic actions to take any time a user is loaded.
        this.userManager.events.addUserLoaded(user => {
        });

    }
    return this.userManager;
}

class OidcAuth {
    constructor() {
        this.userManager = undefined;
    }

    /**
     * Redirect user to IdentityProvider sign in form. On successful authentication, redirect user back to path given by query parameter returnPath.
     * @public
     * @return {undefined}
     */
    redirectSignin() {
        const queryParams = new URLSearchParams(window.location.search);
        const destination = queryParams.get('returnPath') || encodeURIComponent('/');
        ensureUserManager.call(this).signinRedirect({ state: { returnTo: destination } })
            .catch(result => {
                window.location.replace(AuthSettings.LogoutLandingUrl);
            });
    }

    /**
     * @public
     * @return {Promise<Oidc.User>} A promise that resolves to the user that silently signed in.
     */
    silentSignin() {
        return ensureUserManager.call(this).signinSilent();
    }

    /**
     * @public
     * @return {undefined}
     */
    completeRedirectSignin() {
        const userManager = new Oidc.UserManager({ response_mode: 'query' });
        userManager.signinRedirectCallback().then(user => {
            // Pretend we never visited the callback, but went directly to the return URL
            window.location.replace(user.state.returnTo);
        });
    }

    /**
     * @public
     * @return {Promise} A promise that will fail to resolve if signin completion failed.
     */
    completeSilentSignin() {
        const userManager = new Oidc.UserManager({ response_mode: 'query' });
        return userManager.signinSilentCallback();
    }

    /**
     * @public
     * @return {undefined}
     */
    signout() {
        ensureUserManager.call(this).signoutRedirect();
    }

    /**
     * @public
     * @return {undefined}
     */
    completeSignout() {
        const userManager = new Oidc.UserManager({ response_mode: 'query' });
        userManager.signoutRedirectCallback().then(_ => {
            window.location.replace(AuthSettings.LogoutLandingUrl);
        });
    }

    /**
     * @public
     * @return {Promise<Oidc.User>} A promise with the current user, if it exists.
     */
    getCurrentUser() {
        return ensureUserManager.call(this).getUser();
    }
};

export default new OidcAuth();
