import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { bool_type, record_type, class_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { Component } from "react";
import * as react from "react";
import { HTMLAttr } from "../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.18.0/MapUtil.js";

class Props extends Record {
    constructor(error) {
        super();
        this.error = error;
    }
}

function Props_$reflection() {
    return record_type("Loop54.Portal.WebUI.Components.ErrorBoundary.Props", [], Props, () => [["error", class_type("Fable.React.ReactElement")]]);
}

class State extends Record {
    constructor(hasErrors) {
        super();
        this.hasErrors = hasErrors;
    }
}

function State_$reflection() {
    return record_type("Loop54.Portal.WebUI.Components.ErrorBoundary.State", [], State, () => [["hasErrors", bool_type]]);
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = (new State(false));
    }
    componentDidCatch(_arg, _arg_1) {
        const x = this;
        x.setState((_arg_2, _arg_3) => (new State(true)));
    }
    render() {
        const x = this;
        return (x.state).hasErrors ? (x.props).error : (Array.prototype.concat(x.props.children || []));
    }
}

function ErrorBoundary_$reflection() {
    return class_type("Loop54.Portal.WebUI.Components.ErrorBoundary.ErrorBoundary", undefined, ErrorBoundary, class_type("Fable.React.Component`2", [Props_$reflection(), State_$reflection()], Component));
}

function ErrorBoundary_$ctor_48D686DC(props) {
    return new ErrorBoundary(props);
}

export const defaultErrorMessage = (() => {
    let props;
    const children_2 = ["An error occured, please refresh the page. If the problem persist ", (props = [new HTMLAttr(94, ["mailto:support@loop54.com"]), ["style", {
        color: "red",
    }]], react.createElement("a", keyValueList(props, 1), "contact support")), " for assistance."];
    return react.createElement("span", {}, ...children_2);
})();

export function wrapListWith(error, children) {
    const props = new Props(error);
    return react.createElement(ErrorBoundary, props, ...children);
}

export function wrapWith(error, children) {
    return wrapListWith(error, [children]);
}

export function wrapList(children) {
    return wrapListWith(defaultErrorMessage, children);
}

export function wrap(children) {
    return wrapListWith(defaultErrorMessage, [children]);
}

