import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { class_type, union_type, obj_type, array_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../React.fs.js";
import * as react from "react";
import { Menu as Menu_1 } from "antd";

export class Props extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Mode", "DefaultOpenKeys", "DefaultSelectedKeys", "ClassName"];
    }
}

export function Props_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Menu.Props", [], Props, () => [[["Item", string_type]], [["Item", array_type(string_type)]], [["Item", array_type(string_type)]], [["Item", obj_type]]]);
}

export function Menu(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Menu_1, props_1, ...elems);
}

export class SubMenuProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Title", "Key"];
    }
}

export function SubMenuProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Menu.SubMenuProps", [], SubMenuProps, () => [[["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]]]);
}

export function SubMenu(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Menu_1.SubMenu, props_1, ...elems);
}

export class ItemProps extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Key"];
    }
}

export function ItemProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Menu.ItemProps", [], ItemProps, () => [[["Item", string_type]]]);
}

export function Item(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Menu_1.Item, props_1, ...elems);
}

export class ItemGroupProps extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Title"];
    }
}

export function ItemGroupProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Menu.ItemGroupProps", [], ItemGroupProps, () => [[["Item", class_type("Fable.React.ReactElement")]]]);
}

export function ItemGroup(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Menu_1.ItemGroup, props_1, ...elems);
}

