import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { ProductPositionOverridePerNavigation_$reflection, ProductPositionOverridePerQuery_$reflection, ProductPositionOverrideGlobal_$reflection, ExperienceRule_$reflection, EntityNaturalKeyValue_$reflection } from "./EngineSettings.fs.js";
import { array_type, int32_type, record_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";

export class Product extends Record {
    constructor(Entity, Title, Price, ImageUrl) {
        super();
        this.Entity = Entity;
        this.Title = Title;
        this.Price = Price;
        this.ImageUrl = ImageUrl;
    }
}

export function Product_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Product.Product", [], Product, () => [["Entity", EntityNaturalKeyValue_$reflection()], ["Title", string_type], ["Price", string_type], ["ImageUrl", string_type]]);
}

export class ProductQueryRequest extends Record {
    constructor(ExperienceRule, Page) {
        super();
        this.ExperienceRule = ExperienceRule;
        this.Page = (Page | 0);
    }
}

export function ProductQueryRequest_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Product.ProductQueryRequest", [], ProductQueryRequest, () => [["ExperienceRule", ExperienceRule_$reflection()], ["Page", int32_type]]);
}

export class ProductQueryResponse extends Record {
    constructor(Products, TotalProducts) {
        super();
        this.Products = Products;
        this.TotalProducts = (TotalProducts | 0);
    }
}

export function ProductQueryResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Product.ProductQueryResponse", [], ProductQueryResponse, () => [["Products", array_type(Product_$reflection())], ["TotalProducts", int32_type]]);
}

export class ProductWithProductPositionOverride$1 extends Record {
    constructor(Product, ProductPositionOverride) {
        super();
        this.Product = Product;
        this.ProductPositionOverride = ProductPositionOverride;
    }
}

export function ProductWithProductPositionOverride$1_$reflection(gen0) {
    return record_type("Loop54.Portal.Api.Model.Product.ProductWithProductPositionOverride`1", [gen0], ProductWithProductPositionOverride$1, () => [["Product", Product_$reflection()], ["ProductPositionOverride", gen0]]);
}

export class ProductWithProductPositionOverrideResponse extends Record {
    constructor(Global, Query, Navigation) {
        super();
        this.Global = Global;
        this.Query = Query;
        this.Navigation = Navigation;
    }
}

export function ProductWithProductPositionOverrideResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Product.ProductWithProductPositionOverrideResponse", [], ProductWithProductPositionOverrideResponse, () => [["Global", array_type(ProductWithProductPositionOverride$1_$reflection(ProductPositionOverrideGlobal_$reflection()))], ["Query", array_type(ProductWithProductPositionOverride$1_$reflection(ProductPositionOverridePerQuery_$reflection()))], ["Navigation", array_type(ProductWithProductPositionOverride$1_$reflection(ProductPositionOverridePerNavigation_$reflection()))]]);
}

export function ProductWithProductPositionOverrideResponse_get_Empty() {
    return new ProductWithProductPositionOverrideResponse([], [], []);
}

