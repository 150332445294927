import { Record } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, lambda_type, unit_type, bool_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { value } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { int32ToString } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import { SwitchWithLabelProps, SwitchWithLabel } from "./Switch.fs.js";
import FeatureSwitch_1 from "../../../src/Components/Switch/FeatureSwitch.css";

export class Props extends Record {
    constructor(label, id, onChange, stateHook) {
        super();
        this.label = label;
        this.id = id;
        this.onChange = onChange;
        this.stateHook = stateHook;
    }
}

export function Props_$reflection() {
    return record_type("Loop54.Portal.WebUI.Components.FeatureSwitch.Props", [], Props, () => [["label", class_type("Fable.React.ReactElement")], ["id", string_type], ["onChange", lambda_type(bool_type, unit_type)], ["stateHook", class_type("Fable.React.IStateHook`1", [bool_type])]]);
}

export const FeatureSwitch = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Components/Switch/FeatureSwitch.fs") + "#L") + int32ToString(value(19))) + ";") + "Loop54.Portal.WebUI.Components.FeatureSwitch.Props", value("FeatureSwitch"), (props) => SwitchWithLabel(new SwitchWithLabelProps(props.label, props.stateHook[0], (isEnabled) => {
    props.onChange(isEnabled);
    props.stateHook[1](isEnabled);
}, props.id, FeatureSwitch_1.featureSwitch)), undefined, undefined, "Of");

