import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { class_type, int32_type, string_type, bool_type, union_type, obj_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../React.fs.js";
import * as react from "react";
import { Typography, PageHeader as PageHeader_1, Layout as Layout_1 } from "antd";

export class LayoutProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Style", "ClassName"];
    }
}

export function LayoutProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Layout.LayoutProps", [], LayoutProps, () => [[["Item", obj_type]], [["Item", obj_type]]]);
}

export function Layout(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Layout_1, props_1, ...elems);
}

export function Header(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Layout_1.Header, props_1, ...elems);
}

export function Content(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Layout_1.Content, props_1, ...elems);
}

export function PageHeader(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(PageHeader_1, props_1, ...elems);
}

export function Title(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Typography.Title, props_1, ...elems);
}

export class SiderProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Collapsible", "Theme", "Width", "Collapsed", "Trigger"];
    }
}

export function SiderProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.Layout.SiderProps", [], SiderProps, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]]]);
}

export function Sider(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Layout_1.Sider, props_1, ...elems);
}

