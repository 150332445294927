import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, int32_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";

export class GuiExceptionInfo extends Record {
    constructor(Message, Source, LineNo, ColNo, Stack) {
        super();
        this.Message = Message;
        this.Source = Source;
        this.LineNo = (LineNo | 0);
        this.ColNo = (ColNo | 0);
        this.Stack = Stack;
    }
}

export function GuiExceptionInfo_$reflection() {
    return record_type("Loop54.Portal.Api.Model.GuiMonitoring.GuiExceptionInfo", [], GuiExceptionInfo, () => [["Message", string_type], ["Source", string_type], ["LineNo", int32_type], ["ColNo", int32_type], ["Stack", string_type]]);
}

export class GuiUnhandledRejectionInfo extends Record {
    constructor(Reason) {
        super();
        this.Reason = Reason;
    }
}

export function GuiUnhandledRejectionInfo_$reflection() {
    return record_type("Loop54.Portal.Api.Model.GuiMonitoring.GuiUnhandledRejectionInfo", [], GuiUnhandledRejectionInfo, () => [["Reason", string_type]]);
}

