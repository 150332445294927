
const startDeliverApiKey = "usgpub-510e0f1443638341ae3c349f6cbd11d6";

export function initialize() {
    return startdeliver("initialize", startDeliverApiKey);
}

export function sendEvent(getUser, message) {
    const pr = getUser();
    pr.then(((user) => {
        startdeliver("sendEvent", {
            usageType: message,
            userId: user.profile.email,
        });
    }),((value) => {
    }));
}

