import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "../../fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { value } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { defaultOf, int32ToString } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.18.0/String.js";
import * as react from "react";
import { cons, ofArray, empty } from "../../fable_modules/fable-library-js.4.18.0/List.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.18.0/MapUtil.js";
import { TextAreaProps, TextArea } from "../Input/Input.fs.js";
import PortalServerErrorNotification_1 from "../../../src/Components/Notification/PortalServerErrorNotification.css";

export const PortalServerErrorNotification = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/Components/Notification/PortalServerErrorNotification.fs") + "#L") + int32ToString(value(12))) + ";") + "", value("PortalServerErrorNotification"), (props) => {
    let props_3;
    let userMessage;
    const children = [toText(printf("%s [%A]"))(props.portalError.UserMessage)(props.portalError.Timestamp)];
    userMessage = react.createElement("div", {}, ...children);
    const debugInfo = (props.portalError.DebugDetails === defaultOf()) ? empty() : ofArray([(props_3 = [["style", {
        marginTop: "1rem",
    }]], react.createElement("div", keyValueList(props_3, 1), "Debugging information:")), TextArea(ofArray([new TextAreaProps(0, [true]), new TextAreaProps(1, [PortalServerErrorNotification_1.serverErrorTextArea]), new TextAreaProps(2, [props.portalError.DebugDetails]), new TextAreaProps(3, [true])]), empty())]);
    return react.createElement(react.Fragment, {}, ...cons(userMessage, debugInfo));
}, undefined, undefined, "Of");

