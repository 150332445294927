import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { list_type, union_type, bool_type, int32_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";

export const GA_TRACKING_ID = "UA-70002239-2";

export class GaOptionsProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TrackingId", "Name", "ClientId", "SampleRate", "SiteSpeedSampleRate", "AlwaysSendReferrer", "AllowAnchor", "CookieName", "CookieDomain", "CookieFlags", "CookieExpires", "CookieUpdate", "StoreGac", "LegacyCookieDomain", "LegacyHistoryImport", "AllowLinker"];
    }
}

export function GaOptionsProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactGA.GaOptionsProps", [], GaOptionsProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]]]);
}

export class OptionsProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Debug", "TitleCase", "GaOptions", "GaAddress", "AlwaysSendToDefaultTracker", "TestMode", "StandardImplementation", "UseExistingGa", "RedactEmail"];
    }
}

export function OptionsProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactGA.OptionsProps", [], OptionsProps, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", list_type(GaOptionsProps_$reflection())]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]]]);
}

export class EventProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Category", "Action", "Label", "Value", "NonInteraction", "Transport"];
    }
}

export function EventProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactGA.EventProps", [], EventProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export class TimingProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Category", "Variable", "Value", "Label"];
    }
}

export function TimingProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactGA.TimingProps", [], TimingProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", string_type]]]);
}

export class ExceptionProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Description", "Fatal"];
    }
}

export function ExceptionProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactGA.ExceptionProps", [], ExceptionProps, () => [[["Item", string_type]], [["Item", bool_type]]]);
}

