import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { union_type, int32_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../../Interop/React.fs.js";
import * as react from "react";
import react_gravatar from "react-gravatar";
import ReactGravatar_1 from "../../../src/Components/Gravatar/ReactGravatar.css";
import { HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.18.0/MapUtil.js";

export class GravatarProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Email", "Size", "Default", "ClassName"];
    }
}

export function GravatarProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.ReactGravatar.GravatarProps", [], GravatarProps, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function ReactGravatar(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(react_gravatar, props_1, ...elems);
}

export function TopNavGravatar(props, elems) {
    const props_1 = [new HTMLAttr(64, [ReactGravatar_1.reactTopNavGravatar])];
    const children = [ReactGravatar(props, elems)];
    return react.createElement("div", keyValueList(props_1, 1), ...children);
}

