import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";

export class MomentLongDateFormat extends Record {
    constructor(L) {
        super();
        this.L = L;
    }
}

export function MomentLongDateFormat_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.MomentLongDateFormat", [], MomentLongDateFormat, () => [["L", string_type]]);
}

export class MomentConfig extends Record {
    constructor(longDateFormat) {
        super();
        this.longDateFormat = longDateFormat;
    }
}

export function MomentConfig_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.MomentConfig", [], MomentConfig, () => [["longDateFormat", MomentLongDateFormat_$reflection()]]);
}

