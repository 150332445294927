import { defaultArgWith, defaultArg } from "../fable_modules/fable-library-js.4.18.0/Option.js";
import { Union } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { union_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.18.0/String.js";
import { stringHash, equals } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { IdNameTuple_get_Empty } from "../Loop54.Portal.Api.Model/ScopeInfo.fs.js";
import { item, tryFind, contains } from "../fable_modules/fable-library-js.4.18.0/Array.js";

export const CustomerValue_toPart = (option) => defaultArg(option, "");

export const CustomerValue_toText = (option) => defaultArg(option, "?");

export class ScopePartValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Unset", "Any", "Value"];
    }
}

export function ScopePartValue_$reflection() {
    return union_type("Loop54.Portal.WebUI.Hooks.ScopeComponentUtils.ScopePartValue", [], ScopePartValue, () => [[], [], [["Item", string_type]]]);
}

export function ScopePartValueModule_fromPart(value) {
    if (isNullOrWhiteSpace(value)) {
        return new ScopePartValue(1, []);
    }
    else {
        return new ScopePartValue(2, [value]);
    }
}

export function ScopePartValueModule_toPart(_arg) {
    if (_arg.tag === 2) {
        return _arg.fields[0];
    }
    else {
        return "";
    }
}

export function ScopePartValueModule_toUrlParam(_arg) {
    if (_arg.tag === 2) {
        return _arg.fields[0];
    }
    else {
        return "*";
    }
}

export function ScopePartValueModule_fromUrlParam(urlParam) {
    if (isNullOrWhiteSpace(urlParam)) {
        return new ScopePartValue(0, []);
    }
    else if ("*" === urlParam) {
        return new ScopePartValue(1, []);
    }
    else {
        return new ScopePartValue(2, [urlParam]);
    }
}

export function ScopePartValueModule_toText(_arg) {
    switch (_arg.tag) {
        case 0:
            return "";
        case 1:
            return "any";
        default:
            return _arg.fields[0];
    }
}

export function ScopePartValueModule_isConcrete(_arg) {
    if (_arg.tag === 2) {
        return true;
    }
    else {
        return false;
    }
}

export function containsStorefront(info, storefrontId) {
    if (storefrontId.tag === 2) {
        const storefrontId_1 = storefrontId.fields[0];
        if (info.AllowedScope.Storefront.Id === storefrontId_1) {
            return true;
        }
        else {
            return info.AvailableScopeComponents.some((scopeComponent) => (scopeComponent.Storefront.Id === storefrontId_1));
        }
    }
    else {
        return equals(info.AllowedScope.Storefront, IdNameTuple_get_Empty());
    }
}

export function containsLanguage(info, languageCode) {
    if (languageCode.tag === 2) {
        const languageCode_1 = languageCode.fields[0];
        if (info.AllowedScope.LanguageCode === languageCode_1) {
            return true;
        }
        else {
            return info.AvailableScopeComponents.some((scopeComponent) => contains(languageCode_1, scopeComponent.AvailableLanguages, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }));
        }
    }
    else {
        return info.AllowedScope.LanguageCode === "";
    }
}

function containsLanguageInStorefront(info, storefrontId, languageCode) {
    switch (languageCode.tag) {
        case 1:
            return info.AllowedScope.LanguageCode === "";
        case 0:
            return true;
        default: {
            const languageCode_1 = languageCode.fields[0];
            if (info.AllowedScope.LanguageCode === languageCode_1) {
                return true;
            }
            else {
                return info.AvailableScopeComponents.some((scopeComponent) => {
                    if (scopeComponent.Storefront.Id === storefrontId) {
                        return contains(languageCode_1, scopeComponent.AvailableLanguages, {
                            Equals: (x, y) => (x === y),
                            GetHashCode: stringHash,
                        });
                    }
                    else {
                        return false;
                    }
                });
            }
        }
    }
}

function containsStorefrontAndLanguage(info, storefrontId, languageCode) {
    const hasLanguage = (languageCode.tag === 0) ? true : info.AvailableScopeComponents.some((scopeComponent) => contains(ScopePartValueModule_toPart(languageCode), scopeComponent.AvailableLanguages, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }));
    switch (storefrontId.tag) {
        case 1:
            if (equals(info.AllowedScope.Storefront, IdNameTuple_get_Empty())) {
                if (!ScopePartValueModule_isConcrete(languageCode)) {
                    return true;
                }
                else {
                    return hasLanguage;
                }
            }
            else {
                return false;
            }
        case 0:
            if (info.AllowedScope.LanguageCode === ScopePartValueModule_toPart(languageCode)) {
                return true;
            }
            else {
                return hasLanguage;
            }
        default: {
            const storefrontId_1 = storefrontId.fields[0];
            if ((info.AllowedScope.Storefront.Id === storefrontId_1) ? true : equals(info.AllowedScope.Storefront, IdNameTuple_get_Empty())) {
                return containsLanguageInStorefront(info, storefrontId_1, languageCode);
            }
            else {
                return false;
            }
        }
    }
}

export function findInfoForScope(infos, customerId, storefrontId, languageCode) {
    return tryFind((info) => {
        if (info.AllowedScope.Customer.Id === CustomerValue_toPart(customerId)) {
            return containsStorefrontAndLanguage(info, storefrontId, languageCode);
        }
        else {
            return false;
        }
    }, infos);
}

export function findBestMatchForScope(infos, customerId, storefrontId, languageCode) {
    if (infos.length === 0) {
        return undefined;
    }
    else {
        return defaultArgWith(findInfoForScope(infos, customerId, storefrontId, languageCode), () => defaultArgWith(findInfoForScope(infos, customerId, storefrontId, new ScopePartValue(0, [])), () => defaultArgWith(findInfoForScope(infos, customerId, new ScopePartValue(0, []), languageCode), () => defaultArgWith(findInfoForScope(infos, customerId, new ScopePartValue(0, []), new ScopePartValue(0, [])), () => {
            const scopesWithCustomerId = infos.filter((info) => (info.AllowedScope.Customer.Id === CustomerValue_toPart(customerId)));
            if (scopesWithCustomerId.length > 0) {
                return item(0, scopesWithCustomerId);
            }
            else {
                return void 0;
            }
        }))));
    }
}

