import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { union_type, obj_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../React.fs.js";
import * as react from "react";
import { ConfigProvider as ConfigProvider_1 } from "antd";

export class ConfigProviderProps extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Locale"];
    }
}

export function ConfigProviderProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.Antd.ConfigProvider.ConfigProviderProps", [], ConfigProviderProps, () => [[["Item", obj_type]]]);
}

export function ConfigProvider(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(ConfigProvider_1, props_1, ...elems);
}

