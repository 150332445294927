import * as react from "react";
import { defaultOf } from "../fable_modules/fable-library-js.4.18.0/Util.js";

export function useLocalStorage(key, defaultValue) {
    const localStorageState = react.useState(() => {
        const data = localStorage.getItem(key);
        return (data === defaultOf()) ? defaultValue : JSON.parse(data);
    });
    react.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(localStorageState[0]));
    }, [[localStorageState[0], key]]);
    return localStorageState;
}

