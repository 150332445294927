import { createObj } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import * as react from "react";
import { Prompt as Prompt_1, withRouter as withRouter_1, Redirect as Redirect_1, NavLink as NavLink_1, Link as Link_1, Route as Route_1, Switch as Switch_1, BrowserRouter } from "react-router-dom";
import { Record, Union } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, union_type, lambda_type, class_type, list_type, tuple_type, obj_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "./React.fs.js";

export function Router(props, elems) {
    const props_1 = createObj(props);
    return react.createElement(BrowserRouter, props_1, ...elems);
}

export function Switch(props, elems) {
    const props_1 = createObj(props);
    return react.createElement(Switch_1, props_1, ...elems);
}

export class RouteProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Path", "Search", "Exact", "Component", "Render"];
    }
}

export function RouteProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactRouter.RouteProps", [], RouteProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", lambda_type(list_type(tuple_type(string_type, obj_type)), class_type("Fable.React.ReactElement"))]], [["Item", lambda_type(list_type(tuple_type(string_type, obj_type)), class_type("Fable.React.ReactElement"))]]]);
}

export function Route(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Route_1, props_1, ...elems);
}

export class Location extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Pathname", "Search"];
    }
}

export function Location_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactRouter.Location", [], Location, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class NavProps extends Record {
    constructor(location) {
        super();
        this.location = location;
    }
}

export function NavProps_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.ReactRouter.NavProps", [], NavProps, () => [["location", list_type(Location_$reflection())]]);
}

function toLinkProps(props) {
    return {
        to: toProps(props.location),
    };
}

export function Link(props, elems) {
    const props_1 = toLinkProps(props);
    return react.createElement(Link_1, props_1, ...elems);
}

export function NavLink(props, elems) {
    const props_1 = toLinkProps(props);
    return react.createElement(NavLink_1, props_1, ...elems);
}

export function RedirectFrom(props, propsFrom, exact, elems) {
    const props_1 = {
        to: toProps(props.location),
        from: propsFrom,
        exact: exact,
    };
    return react.createElement(Redirect_1, props_1, ...elems);
}

export function Redirect(props, elems) {
    const props_1 = toLinkProps(props);
    return react.createElement(Redirect_1, props_1, ...elems);
}

export const withRouter = withRouter_1;

export class PromptProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["When", "Message"];
    }
}

export function PromptProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Interop.ReactRouter.PromptProps", [], PromptProps, () => [[["Item", bool_type]], [["Item", string_type]]]);
}

export function Prompt(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Prompt_1, props_1, ...elems);
}

