import { map, defaultArg, some } from "../../fable_modules/fable-library-js.4.18.0/Option.js";
import { class_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import Notifications from "../../../src/Services/Notifications/Notifications.css";
import { toProps } from "../../Interop/React.fs.js";
import * as react from "react";
import react_notification_system from "react-notification-system";
import { arrayHash } from "../../fable_modules/fable-library-js.4.18.0/Util.js";
import { Prop, HTMLAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { empty, ofArray } from "../../fable_modules/fable-library-js.4.18.0/List.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.18.0/MapUtil.js";
import { IconProps, CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, InfoCircleFilled } from "../../Interop/Antd/Icon.fs.js";

export class ConsoleNotifications {
    constructor() {
    }
    Send(level, title, message, _arg) {
        switch (level) {
            case "warning": {
                console.warn(some("%s %O"), title, message);
                break;
            }
            case "error": {
                console.error(some("%s %O"), title, message);
                break;
            }
            default:
                console.info(some("%s %O"), title, message);
        }
    }
}

export function ConsoleNotifications_$reflection() {
    return class_type("Loop54.Portal.WebUI.Services.Notifications.Notifications.ConsoleNotifications", undefined, ConsoleNotifications);
}

export function ConsoleNotifications_$ctor() {
    return new ConsoleNotifications();
}

export const styles = Notifications;

function reactNotificationContainer(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(react_notification_system, props_1, ...elems);
}

export class ReactNotifications {
    constructor(system) {
        this.system = system;
        this.consoleNotifications = ConsoleNotifications_$ctor();
    }
    Send(level, title, message, createNotificationContent) {
        const this$ = this;
        this$.consoleNotifications.Send(level, title, message);
        let notificationObj;
        const children = ReactNotifications__createChildren(this$, level, message, defaultArg(createNotificationContent, (s) => s));
        notificationObj = {
            autoDismiss: ReactNotifications__duration_Z721C83C5(this$, level),
            children: children,
            dismissible: "button",
            level: level,
            title: title,
            uid: arrayHash([level, title, message]),
        };
        this$.system.addNotification(notificationObj);
    }
}

export function ReactNotifications_$reflection() {
    return class_type("Loop54.Portal.WebUI.Services.Notifications.Notifications.ReactNotifications", undefined, ReactNotifications);
}

export function ReactNotifications_$ctor_Z5966C024(system) {
    return new ReactNotifications(system);
}

/**
 * Return a container element for React-based notifications. This container is configured with unstyled notifications
 * in order for us to override the (hideous!) default styling with CSS that mimicks the Antd styling.
 */
export function ReactNotifications_Container_Z3B1FAE2A(ref) {
    const props = [new HTMLAttr(64, [styles.notificationSystem])];
    const children = [reactNotificationContainer(ofArray([new Prop(3, [ref]), ["style", false]]), empty())];
    return react.createElement("div", keyValueList(props, 1), ...children);
}

export function ReactNotifications__duration_Z721C83C5(this$, level) {
    switch (level) {
        case "warning":
        case "error":
            return 0;
        default:
            return 5;
    }
}

export function ReactNotifications__createChildren(this$, level, message, createNotificationContent) {
    let props_4, children;
    return [((level === "info") ? ((props_1) => ((elems_1) => InfoCircleFilled(props_1, elems_1))) : ((level === "warning") ? ((props_2) => ((elems_2) => ExclamationCircleFilled(props_2, elems_2))) : ((level === "error") ? ((props_3) => ((elems_3) => CloseCircleFilled(props_3, elems_3))) : ((props) => ((elems) => CheckCircleFilled(props, elems))))))(ofArray([new IconProps(0, [`${styles.notificationIcon}`]), new IconProps(6, ["icon"])]))(empty()), (props_4 = [new HTMLAttr(64, [styles.notificationContent]), new Prop(0, ["message"])], (children = [createNotificationContent(message)], react.createElement("div", keyValueList(props_4, 1), ...children)))];
}

export const Context = (() => {
    const defaultValue = ConsoleNotifications_$ctor();
    return react.createContext(defaultValue);
})();

/**
 * Initialise React-based notifications if a container element is available, otherwise default to browser console only.
 */
export function get$(container) {
    return defaultArg(map(ReactNotifications_$ctor_Z5966C024, container), ConsoleNotifications_$ctor());
}

