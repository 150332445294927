import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { int32_type, float64_type, union_type, lambda_type, unit_type, string_type, bool_type, class_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../../Interop/React.fs.js";
import * as react from "react";
import { InputNumber as InputNumber_1, Input as Input_1 } from "antd";

export class InputProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddonAfter", "AddonBefore", "AllowClear", "Placeholder", "AutoComplete", "AutoFocus", "ClassName", "DefaultValue", "Disabled", "Focus", "OnBlur", "OnChange", "OnPressEnter", "Prefix", "Size", "Suffix", "Type", "Value"];
    }
}

export function InputProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Input.InputProps", [], InputProps, () => [[["Item", class_type("Fable.React.ReactElement")]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Input(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Input_1, props_1, ...elems);
}

export class SearchProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddonAfter", "AddonBefore", "AllowClear", "ClassName", "DefaultValue", "OnChange", "OnSearch", "Prefix", "Size"];
    }
}

export function SearchProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Input.SearchProps", [], SearchProps, () => [[["Item", class_type("Fable.React.ReactElement")]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(string_type, unit_type)]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]]]);
}

export function Search(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Input_1.Search, props_1, ...elems);
}

export class TextAreaProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AutoSize", "ClassName", "DefaultValue", "Disabled"];
    }
}

export function TextAreaProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Input.TextAreaProps", [], TextAreaProps, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]]]);
}

export function TextArea(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Input_1.TextArea, props_1, ...elems);
}

export class InputGroupProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Compact", "Size"];
    }
}

export function InputGroupProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Input.InputGroupProps", [], InputGroupProps, () => [[["Item", bool_type]], [["Item", string_type]]]);
}

export function InputGroup(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Input_1.Group, props_1, ...elems);
}

export class InputNumberProps$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AutoFocus", "Bordered", "ClassName", "DecimalSeparator", "DefaultValue", "Disabled", "Formatter", "Keyboard", "Max", "Min", "Parser", "Precision", "ReadOnly", "Size", "Step", "StringMode", "Value", "OnChange", "OnPressEnter"];
    }
}

export function InputNumberProps$1_$reflection(gen0) {
    return union_type("Loop54.Portal.WebUI.Components.Input.InputNumberProps`1", [gen0], InputNumberProps$1, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [], [["Item", string_type]], [["Item", bool_type]], [["Item", lambda_type(string_type, string_type)]], [["Item", bool_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", lambda_type(string_type, gen0)]], [["Item", int32_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", gen0]], [["Item", bool_type]], [["Item", gen0]], [["Item", lambda_type(string_type, unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]]]);
}

export function InputNumber(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(InputNumber_1, props_1, ...elems);
}

