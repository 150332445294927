import { Exception, toString } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.18.0/MapUtil.js";
import { class_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.18.0/String.js";
import { Types_RequestProperties, Types_HttpRequestHeaders } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { iterate, singleton, append, cons } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { reject } from "../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { PortalServerError_get_FailedToParseError } from "../Loop54.Portal.Api.Model/Errors.fs.js";
import { KpiQueryFilter__get_toArgs } from "../Loop54.Portal.Api.Model/KpiQueryFilter.fs.js";

/**
 * Call <see cref="Fetch.GlobalFetch.fetch"/> directly to resolve the promise with a <see cref="Response"/>
 * object even if the HTTP response status code indicates an error.
 */
export function fetch$(url, init) {
    return fetch(toString(url), keyValueList(init, 1));
}

export class UnauthorizedException extends Exception {
    constructor(message) {
        super(message);
    }
}

export function UnauthorizedException_$reflection() {
    return class_type("Loop54.Portal.WebUI.Utils.ApiUtils.UnauthorizedException", undefined, UnauthorizedException, class_type("System.Exception"));
}

export function UnauthorizedException_$ctor_Z721C83C5(message) {
    return new UnauthorizedException(message);
}

export class AccessDeniedException extends Exception {
    constructor(message) {
        super(message);
    }
}

export function AccessDeniedException_$reflection() {
    return class_type("Loop54.Portal.WebUI.Utils.ApiUtils.AccessDeniedException", undefined, AccessDeniedException, class_type("System.Exception"));
}

export function AccessDeniedException_$ctor_Z721C83C5(message) {
    return new AccessDeniedException(message);
}

export class RateLimitedException extends Exception {
    constructor(message) {
        super(message);
    }
}

export function RateLimitedException_$reflection() {
    return class_type("Loop54.Portal.WebUI.Utils.ApiUtils.RateLimitedException", undefined, RateLimitedException, class_type("System.Exception"));
}

export function RateLimitedException_$ctor_Z721C83C5(message) {
    return new RateLimitedException(message);
}

export class InternalServerErrorException extends Exception {
    constructor(errorInfo) {
        super(JSON.stringify(errorInfo));
    }
}

export function InternalServerErrorException_$reflection() {
    return class_type("Loop54.Portal.WebUI.Utils.ApiUtils.InternalServerErrorException", undefined, InternalServerErrorException, class_type("System.Exception"));
}

export function InternalServerErrorException_$ctor_10072057(errorInfo) {
    return new InternalServerErrorException(errorInfo);
}

export function fetchAs(user, url, extraReqHeaders, extraReqProps) {
    const pr_1 = fetch$(url, cons(new Types_RequestProperties(1, [keyValueList(cons(new Types_HttpRequestHeaders(5, [toText(printf("Bearer %s"))(user.accessToken)]), extraReqHeaders), 0)]), extraReqProps));
    return pr_1.then((response) => {
        if (response.ok) {
            return response.text();
        }
        else {
            let message;
            const arg_1 = response.statusText;
            const arg_2 = (response.status) | 0;
            const arg_3 = toString(url);
            message = toText(printf("%s (%d) for %s"))(arg_1)(arg_2)(arg_3);
            const matchValue_1 = (response.status) | 0;
            switch (matchValue_1) {
                case 401:
                    return reject(UnauthorizedException_$ctor_Z721C83C5(message));
                case 403:
                    return reject(AccessDeniedException_$ctor_Z721C83C5(message));
                case 429:
                    return reject(RateLimitedException_$ctor_Z721C83C5(message));
                case 500: {
                    const pr = response.text();
                    return pr.then((body) => reject(InternalServerErrorException_$ctor_10072057((() => {
                        try {
                            return JSON.parse(body);
                        }
                        catch (matchValue_2) {
                            return PortalServerError_get_FailedToParseError();
                        }
                    })())));
                }
                default:
                    return reject(new Error(message));
            }
        }
    });
}

export function unboxAndStringify(value) {
    return JSON.stringify(value);
}

export function createUrlWithPath(api, path) {
    const url = toText(printf("%s/%s"))(api)(path);
    return new URL(url);
}

export function siteDateArgs(filter) {
    return KpiQueryFilter__get_toArgs(filter);
}

export function siteNavDateArgs(filter, navigationType) {
    const list_1 = siteDateArgs(filter);
    return append(singleton(["navigationType", singleton(toString(navigationType))]), list_1);
}

export function addArgs(args, url) {
    iterate((tupledArg) => {
        iterate((query) => {
            const tupledArg_1 = [tupledArg[0], query];
            const objectArg = url.searchParams;
            objectArg.append(tupledArg_1[0], tupledArg_1[1]);
        }, tupledArg[1]);
    }, args);
    return url;
}

