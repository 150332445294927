import * as antd from "../node_modules/antd/dist/antd.less";
import * as oidc_client from "oidc-client";
import { FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E } from "./fable_modules/Fable.React.9.4.0/Fable.React.FunctionComponent.fs.js";
import { some, value as value_2 } from "./fable_modules/fable-library-js.4.18.0/Option.js";
import { stringHash, int32ToString } from "./fable_modules/fable-library-js.4.18.0/Util.js";
import { routes, SigninRedirect, getCurrentUser } from "./Services/Auth/Auth.fs.js";
import * as react from "react";
import { defaultPage, Props, Controller } from "./Views/Portal.fs.js";
import { contains, cons, ofArray, singleton, empty } from "./fable_modules/fable-library-js.4.18.0/List.js";
import { Context, UrlParamsStoreContext_$ctor } from "./Hooks/Query.fs.js";
import { Router, Switch, NavProps, Location, RedirectFrom } from "./Interop/ReactRouter.fs.js";
import { ReactNotifications_Container_Z3B1FAE2A, Context as Context_1, get$ } from "./Services/Notifications/Notifications.fs.js";
import { QueryParamProvider } from "./Interop/UseQueryParams.fs.js";
import { endpoint } from "./Services/Api/Api.fs.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.18.0/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { unboxAndStringify, fetchAs } from "./Utils/ApiUtils.fs.js";
import { Types_RequestProperties, Types_HttpRequestHeaders } from "./fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { GuiUnhandledRejectionInfo, GuiExceptionInfo } from "./Loop54.Portal.Api.Model/GuiMonitoring.fs.js";
import { toString, isException } from "./fable_modules/fable-library-js.4.18.0/Types.js";
import * as react_dom from "react-dom";
import { wrap } from "./Components/ErrorBoundary.fs.js";



export const AppAuthentication = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_2("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/App.fs") + "#L") + int32ToString(value_2(25))) + ";") + "Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,System.Object]]", value_2("AppAuthentication"), (props) => {
    const user = getCurrentUser;
    const content = react.useState("Loading...");
    react.useEffect(() => {
        let pr_1;
        const pr = user();
        pr_1 = (pr.then((_arg) => {
            content[1]((_arg_1) => Controller(new Props(user, props.location.pathname)));
        }));
        pr_1.then(void 0, ((ex) => {
            content[1]((_arg_2) => SigninRedirect(empty()));
        }));
    }, []);
    return content[0];
}, undefined, undefined, "Of");

export const App = FunctionComponentPreparedRenderFunctionCache_GetOrAdd_3560CE5E((((value_2("/home/tcagent/agent/work/fc6800de859d0248/portal/Loop54.Portal.WebUI/src/App.fs") + "#L") + int32ToString(value_2(48))) + ";") + "Microsoft.FSharp.Collections.FSharpList`1[System.Tuple`2[System.String,System.Object]]", value_2("App"), (props) => {
    const cachedQueryParamsContext = UrlParamsStoreContext_$ctor();
    const notificationContainer = react.useRef(undefined);
    const getDefaultPage = RedirectFrom(new NavProps(singleton(new Location(0, [defaultPage]))), "/", true, empty());
    const content = react.useState(empty());
    react.useEffect(() => {
        content[1]((_arg_1) => {
            let value_1, children_1, children;
            return singleton((value_1 = get$(notificationContainer.current), (children_1 = [(children = [Switch(empty(), ofArray([getDefaultPage, routes, AppAuthentication(empty())]))], react.createElement(Context.Provider, {
                value: cachedQueryParamsContext,
            }, ...children))], react.createElement(Context_1.Provider, {
                value: value_1,
            }, ...children_1))));
        });
    }, []);
    return Router(empty(), singleton(QueryParamProvider(cons(ReactNotifications_Container_Z3B1FAE2A(notificationContainer), content[0]))));
}, undefined, undefined, "Of");

function uriGuiMonitoring(action) {
    return endpoint(toText(printf("GuiMonitoring/%s"))(action));
}

function sendMonitoringRequest(uri, exceptionObject) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getCurrentUser().then((_arg) => (((user_1) => ((requestBody) => ((url) => fetchAs(user_1, url, singleton(new Types_HttpRequestHeaders(11, ["application/json"])), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [unboxAndStringify(requestBody)])])))))(_arg)(exceptionObject)(uri))))));
    pr.then(void 0, ((x) => {
        console.error(some("couldn\'t send error info"), x);
    }));
}

const uriError = uriGuiMonitoring("RegisterError");

window.onerror = ((message, source, lineno, colno, error) => {
    const error_1 = error;
    const patternInput = (error_1 == null) ? [message, "<no stack>"] : [error_1.toString(), error_1.stack];
    const msg = patternInput[0];
    if (contains(msg, ofArray(["ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications."]), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })) {
    }
    else {
        sendMonitoringRequest(uriError, new GuiExceptionInfo(msg, source, lineno, colno, patternInput[1]));
    }
});

const uriUnhandledRejection = uriGuiMonitoring("RegisterUnhandledRejection");

window.onunhandledrejection = ((event) => {
    let matchValue;
    const event_1 = event;
    sendMonitoringRequest(uriUnhandledRejection, (matchValue = event_1.reason, isException(matchValue) ? (new GuiUnhandledRejectionInfo(((event_1.reason.toString()) + "\n") + event_1.reason.stack)) : (new GuiUnhandledRejectionInfo(toString(event_1.reason)))));
});

react_dom.render(wrap(App(empty())), document.getElementById("app"));

if (!(module.hot == null)) {
    module.hot.accept();
}

