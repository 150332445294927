import { Exception } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { class_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import * as react from "react";

export class InvalidUserException extends Exception {
    constructor(message) {
        super(message);
    }
}

export function InvalidUserException_$reflection() {
    return class_type("Loop54.Portal.WebUI.Services.Auth.User.InvalidUserException", undefined, InvalidUserException, class_type("System.Exception"));
}

export function InvalidUserException_$ctor_Z721C83C5(message) {
    return new InvalidUserException(message);
}

export const Context = react.createContext(() => {
    throw new Error("User context missing");
});

