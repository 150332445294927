import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, array_type, tuple_type, lambda_type, unit_type, option_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { ScopePartValueModule_toPart, CustomerValue_toPart, findBestMatchForScope as findBestMatchForScope_1, containsLanguage, ScopePartValueModule_isConcrete, containsStorefront, ScopePartValueModule_fromPart, ScopePartValue, ScopePartValueModule_toText, findInfoForScope, ScopePartValue_$reflection } from "./ScopeComponentUtils.fs.js";
import { IdNameTuple_get_Empty, ScopeInfo_$reflection } from "../Loop54.Portal.Api.Model/ScopeInfo.fs.js";
import * as react from "react";
import { Context as Context_1 } from "./UserScopeInformation.fs.js";
import { useQueryParam } from "./Query.fs.js";
import { ScopePartParam, CustomerIdParam } from "../Interop/CustomQueryParams.fs.js";
import { useHistory } from "react-router-dom";
import { value as value_1, some, defaultArg } from "../fable_modules/fable-library-js.4.18.0/Option.js";
import { collect, concat, map, item } from "../fable_modules/fable-library-js.4.18.0/Array.js";
import { Array_distinct, distinctBy, Array_distinctBy } from "../fable_modules/fable-library-js.4.18.0/Seq2.js";
import { equals, stringHash } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { reduce, toArray, singleton, append } from "../fable_modules/fable-library-js.4.18.0/Seq.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.18.0/String.js";
import { QueryFilter_get_NotInitialized } from "../Loop54.Portal.Api.Model/KpiQueryFilter.fs.js";

export class QuerySelectionHook extends Record {
    constructor(setSelectedCustomer, setSelectedStorefront, setSelectedLanguage, selectedCustomerId, selectedStorefrontId, selectedLanguageCode, customerOptions, storefrontOptions, languageOptions, findBestMatchForScope) {
        super();
        this.setSelectedCustomer = setSelectedCustomer;
        this.setSelectedStorefront = setSelectedStorefront;
        this.setSelectedLanguage = setSelectedLanguage;
        this.selectedCustomerId = selectedCustomerId;
        this.selectedStorefrontId = selectedStorefrontId;
        this.selectedLanguageCode = selectedLanguageCode;
        this.customerOptions = customerOptions;
        this.storefrontOptions = storefrontOptions;
        this.languageOptions = languageOptions;
        this.findBestMatchForScope = findBestMatchForScope;
    }
}

export function QuerySelectionHook_$reflection() {
    return record_type("Loop54.Portal.WebUI.Hooks.QuerySelection.QuerySelectionHook", [], QuerySelectionHook, () => [["setSelectedCustomer", lambda_type(option_type(string_type), unit_type)], ["setSelectedStorefront", lambda_type(ScopePartValue_$reflection(), unit_type)], ["setSelectedLanguage", lambda_type(ScopePartValue_$reflection(), unit_type)], ["selectedCustomerId", option_type(string_type)], ["selectedStorefrontId", ScopePartValue_$reflection()], ["selectedLanguageCode", ScopePartValue_$reflection()], ["customerOptions", array_type(tuple_type(string_type, string_type))], ["storefrontOptions", array_type(tuple_type(string_type, string_type))], ["languageOptions", array_type(ScopePartValue_$reflection())], ["findBestMatchForScope", lambda_type(unit_type, option_type(ScopeInfo_$reflection()))]]);
}

export function useQuerySelection(_arg) {
    let storefronts, array_3, array_5, languages, array_8, array_12;
    const available = react.useState([]);
    const allowAny = react.useState(false);
    const userScopeInfos = react.useContext(Context_1);
    const patternInput = useQueryParam("customerId", CustomerIdParam);
    const setCustomerId = patternInput[1];
    const customerId = patternInput[0];
    const patternInput_1 = useQueryParam("storefrontId", ScopePartParam);
    const storefrontId = patternInput_1[0];
    const setStorefrontId = patternInput_1[1];
    const patternInput_2 = useQueryParam("languageCode", ScopePartParam);
    const setLanguageCode = patternInput_2[1];
    const languageCode = patternInput_2[0];
    const history = useHistory();
    react.useEffect(() => {
        const matchValue = history.location.pathname;
        switch (matchValue) {
            case "/configuration/boostBury":
            case "/configuration/synonyms":
            case "/merchandising/redirects":
            case "/merchandising/experience":
            case "/merchandising/experience/editor": {
                allowAny[1](false);
                break;
            }
            default:
                allowAny[1](true);
        }
    }, [history.location.pathname]);
    react.useEffect(() => {
        if (userScopeInfos == null) {
            available[1]([]);
        }
        else {
            const infos = userScopeInfos;
            available[1](infos.AllowedScopes);
            const info = infos;
            if (findInfoForScope(info.AllowedScopes, customerId, storefrontId, languageCode) == null) {
                const toTextOrNone = (scopePart) => {
                    switch (scopePart.tag) {
                        case 2:
                            return scopePart.fields[0];
                        case 0:
                            return "none";
                        default:
                            return ScopePartValueModule_toText(new ScopePartValue(1, []));
                    }
                };
                const currentCustomer = defaultArg(customerId, "none");
                console.warn(some("Could not find scope: %s - %s - %s. Selecting the default scope: %s - %s - %s"), currentCustomer, toTextOrNone(storefrontId), toTextOrNone(languageCode), info.DefaultScope.AllowedScope.Customer.Name, info.DefaultScope.AllowedScope.Storefront.Name, info.DefaultScope.AllowedScope.LanguageCode);
                setCustomerId(info.DefaultScope.AllowedScope.Customer.Id);
                setStorefrontId(ScopePartValueModule_fromPart(info.DefaultScope.AllowedScope.Storefront.Id));
                setLanguageCode(ScopePartValueModule_fromPart(info.DefaultScope.AllowedScope.LanguageCode));
            }
        }
    }, [userScopeInfos]);
    const assureValidStorefrontId = (bestMatch) => {
        if (!containsStorefront(bestMatch, storefrontId)) {
            if ((allowAny[0]) ? true : ScopePartValueModule_isConcrete(ScopePartValueModule_fromPart(bestMatch.AllowedScope.Storefront.Id))) {
                setStorefrontId(ScopePartValueModule_fromPart(bestMatch.AllowedScope.Storefront.Id));
            }
            else {
                setStorefrontId(ScopePartValueModule_fromPart(item(0, bestMatch.AvailableScopeComponents).Storefront.Id));
            }
        }
    };
    const assureValidLanguageCode = (bestMatch_1) => {
        if (!containsLanguage(bestMatch_1, languageCode)) {
            if ((allowAny[0]) ? true : ScopePartValueModule_isConcrete(ScopePartValueModule_fromPart(bestMatch_1.AllowedScope.LanguageCode))) {
                setLanguageCode(ScopePartValueModule_fromPart(bestMatch_1.AllowedScope.LanguageCode));
            }
            else {
                setLanguageCode(ScopePartValueModule_fromPart(item(0, item(0, bestMatch_1.AvailableScopeComponents).AvailableLanguages)));
            }
        }
    };
    return new QuerySelectionHook((customer) => {
        const bestMatch_2 = findBestMatchForScope_1(available[0], customer, storefrontId, languageCode);
        if (bestMatch_2 != null) {
            assureValidStorefrontId(value_1(bestMatch_2));
            assureValidLanguageCode(value_1(bestMatch_2));
            setCustomerId(customer);
        }
    }, (storefront) => {
        const bestMatch_3 = findBestMatchForScope_1(available[0], customerId, storefront, languageCode);
        if (bestMatch_3 != null) {
            assureValidLanguageCode(value_1(bestMatch_3));
            setStorefrontId(storefront);
        }
    }, (language) => {
        const bestMatch_4 = findBestMatchForScope_1(available[0], customerId, storefrontId, language);
        if (bestMatch_4 != null) {
            assureValidStorefrontId(value_1(bestMatch_4));
            setLanguageCode(language);
        }
    }, customerId, storefrontId, languageCode, map((idNameTuple_1) => [idNameTuple_1.Id, idNameTuple_1.Name], Array_distinctBy((idNameTuple) => idNameTuple.Id, map((info_1) => info_1.AllowedScope.Customer, available[0]), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })), (storefronts = map((info_3) => {
        const source_1 = map((scopeComponent) => scopeComponent.Storefront, info_3.AvailableScopeComponents);
        return append(singleton(info_3.AllowedScope.Storefront), source_1);
    }, (array_3 = (available[0]), array_3.filter((info_2) => (info_2.AllowedScope.Customer.Id === CustomerValue_toPart(customerId))))), (storefronts.length > 0) ? map((idNameTuple_5) => {
        if (isNullOrWhiteSpace(idNameTuple_5.Id)) {
            return [ScopePartValueModule_toPart(new ScopePartValue(1, [])), "any"];
        }
        else {
            return [idNameTuple_5.Id, idNameTuple_5.Name];
        }
    }, (array_5 = toArray(distinctBy((idNameTuple_3) => idNameTuple_3.Id, reduce((idNameTuple_2, acc) => append(acc, idNameTuple_2), storefronts), {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    })), array_5.filter((idNameTuple_4) => {
        if (allowAny[0]) {
            return true;
        }
        else {
            return !isNullOrWhiteSpace(idNameTuple_4.Id);
        }
    }))) : []), (languages = map((info_5) => concat([[info_5.AllowedScope.LanguageCode], collect((scopeComponent_2) => scopeComponent_2.AvailableLanguages, info_5.AvailableScopeComponents)]), (array_8 = (available[0]), array_8.filter((info_4) => {
        if (info_4.AllowedScope.Customer.Id === CustomerValue_toPart(customerId)) {
            if (equals(info_4.AllowedScope.Storefront, IdNameTuple_get_Empty()) ? true : info_4.AvailableScopeComponents.some((scopeComponent_1) => (scopeComponent_1.Storefront.Id === ScopePartValueModule_toPart(storefrontId)))) {
                return true;
            }
            else {
                return info_4.AllowedScope.Storefront.Id === ScopePartValueModule_toPart(storefrontId);
            }
        }
        else {
            return false;
        }
    }))), (languages.length > 0) ? map(ScopePartValueModule_fromPart, (array_12 = Array_distinct(languages.reduce((languages_1, acc_1) => concat([acc_1, languages_1])), {
        Equals: (x_2, y_2) => (x_2 === y_2),
        GetHashCode: stringHash,
    }), array_12.filter((language_1) => {
        if (allowAny[0]) {
            return true;
        }
        else {
            return !isNullOrWhiteSpace(language_1);
        }
    }))) : []), () => findBestMatchForScope_1(available[0], customerId, storefrontId, languageCode));
}

export const Context = (() => {
    const defaultValue = QueryFilter_get_NotInitialized();
    return react.createContext(defaultValue);
})();

