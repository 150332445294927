import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { getEnumName, enum_type, parseEnum, class_type, float64_type, int64_type, record_type, array_type, int32_type, option_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { ofSeq } from "../fable_modules/fable-library-js.4.18.0/Map.js";
import { empty } from "../fable_modules/fable-library-js.4.18.0/Seq.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.18.0/Util.js";

export class QueryResultSet$1 extends Record {
    constructor(ResultSetId, TotalResultCount, FirstResultPage) {
        super();
        this.ResultSetId = ResultSetId;
        this.TotalResultCount = (TotalResultCount | 0);
        this.FirstResultPage = FirstResultPage;
    }
}

export function QueryResultSet$1_$reflection(gen0) {
    return record_type("Loop54.Portal.Api.Model.Kpi.QueryResultSet`1", [gen0], QueryResultSet$1, () => [["ResultSetId", option_type(string_type)], ["TotalResultCount", int32_type], ["FirstResultPage", array_type(gen0)]]);
}

export class MainKpiResults extends Record {
    constructor(NavigationLabel, NavigationCount, InteractionLabel, InteractionCount, InteractionRate, InteractionPos, InteractionsPerNav, InteractionPrice) {
        super();
        this.NavigationLabel = NavigationLabel;
        this.NavigationCount = NavigationCount;
        this.InteractionLabel = InteractionLabel;
        this.InteractionCount = InteractionCount;
        this.InteractionRate = InteractionRate;
        this.InteractionPos = InteractionPos;
        this.InteractionsPerNav = InteractionsPerNav;
        this.InteractionPrice = InteractionPrice;
    }
}

export function MainKpiResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.MainKpiResults", [], MainKpiResults, () => [["NavigationLabel", string_type], ["NavigationCount", int64_type], ["InteractionLabel", string_type], ["InteractionCount", int64_type], ["InteractionRate", float64_type], ["InteractionPos", float64_type], ["InteractionsPerNav", float64_type], ["InteractionPrice", float64_type]]);
}

export function MainKpiResults_get_Empty() {
    return new MainKpiResults("N/A", 0n, "N/A", 0n, 0, 0, 0, 0);
}

export class GroupedMainKpiResults extends Record {
    constructor(Main, Revenue) {
        super();
        this.Main = Main;
        this.Revenue = Revenue;
    }
}

export function GroupedMainKpiResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.GroupedMainKpiResults", [], GroupedMainKpiResults, () => [["Main", MainKpiResults_$reflection()], ["Revenue", MainKpiResults_$reflection()]]);
}

export class AllInteractionTypesMainKpiResults extends Record {
    constructor(KpiResults) {
        super();
        this.KpiResults = KpiResults;
    }
}

export function AllInteractionTypesMainKpiResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.AllInteractionTypesMainKpiResults", [], AllInteractionTypesMainKpiResults, () => [["KpiResults", array_type(MainKpiResults_$reflection())]]);
}

export class RequestIdentityFields extends Record {
    constructor(RequestLabel, RequestType) {
        super();
        this.RequestLabel = RequestLabel;
        this.RequestType = RequestType;
    }
}

export function RequestIdentityFields_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.RequestIdentityFields", [], RequestIdentityFields, () => [["RequestLabel", string_type], ["RequestType", string_type]]);
}

export class RequestDisplayFields extends Record {
    constructor(Name, Value, Details) {
        super();
        this.Name = Name;
        this.Value = Value;
        this.Details = Details;
    }
}

export function RequestDisplayFields_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.RequestDisplayFields", [], RequestDisplayFields, () => [["Name", string_type], ["Value", string_type], ["Details", string_type]]);
}

export class CategoryAliasData extends Record {
    constructor(IdentityFields, DisplayFields) {
        super();
        this.IdentityFields = IdentityFields;
        this.DisplayFields = DisplayFields;
    }
}

export function CategoryAliasData_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.CategoryAliasData", [], CategoryAliasData, () => [["IdentityFields", RequestIdentityFields_$reflection()], ["DisplayFields", RequestDisplayFields_$reflection()]]);
}

export class RequestMappedFields extends Record {
    constructor(Title, Url, Price, ImageUrl) {
        super();
        this.Title = Title;
        this.Url = Url;
        this.Price = Price;
        this.ImageUrl = ImageUrl;
    }
}

export function RequestMappedFields_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.RequestMappedFields", [], RequestMappedFields, () => [["Title", option_type(string_type)], ["Url", option_type(string_type)], ["Price", option_type(float64_type)], ["ImageUrl", option_type(string_type)]]);
}

export class TrendingValuesResults extends Record {
    constructor(RequestIdentity, RequestDisplay, RequestMapping, Previous, Recent) {
        super();
        this.RequestIdentity = RequestIdentity;
        this.RequestDisplay = RequestDisplay;
        this.RequestMapping = RequestMapping;
        this.Previous = Previous;
        this.Recent = Recent;
    }
}

export function TrendingValuesResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.TrendingValuesResults", [], TrendingValuesResults, () => [["RequestIdentity", RequestIdentityFields_$reflection()], ["RequestDisplay", RequestDisplayFields_$reflection()], ["RequestMapping", RequestMappedFields_$reflection()], ["Previous", int64_type], ["Recent", int64_type]]);
}

export class ProductsWithInteractionsResults extends Record {
    constructor(RequestLabel, Volume, Title, Url, Price, ImageUrl) {
        super();
        this.RequestLabel = RequestLabel;
        this.Volume = Volume;
        this.Title = Title;
        this.Url = Url;
        this.Price = Price;
        this.ImageUrl = ImageUrl;
    }
}

export function ProductsWithInteractionsResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.ProductsWithInteractionsResults", [], ProductsWithInteractionsResults, () => [["RequestLabel", string_type], ["Volume", int64_type], ["Title", option_type(string_type)], ["Url", option_type(string_type)], ["Price", option_type(float64_type)], ["ImageUrl", option_type(string_type)]]);
}

export class PerformanceResults extends Record {
    constructor(RequestIdentity, RequestDisplay, RequestMapping, Count, InteractionRate, InteractionPos, InteractionsPerNav, InteractionPrice) {
        super();
        this.RequestIdentity = RequestIdentity;
        this.RequestDisplay = RequestDisplay;
        this.RequestMapping = RequestMapping;
        this.Count = Count;
        this.InteractionRate = InteractionRate;
        this.InteractionPos = InteractionPos;
        this.InteractionsPerNav = InteractionsPerNav;
        this.InteractionPrice = InteractionPrice;
    }
}

export function PerformanceResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.PerformanceResults", [], PerformanceResults, () => [["RequestIdentity", RequestIdentityFields_$reflection()], ["RequestDisplay", RequestDisplayFields_$reflection()], ["RequestMapping", RequestMappedFields_$reflection()], ["Count", int64_type], ["InteractionRate", float64_type], ["InteractionPos", float64_type], ["InteractionsPerNav", float64_type], ["InteractionPrice", float64_type]]);
}

export class RefinementResults extends Record {
    constructor(ByQuery) {
        super();
        this.ByQuery = ByQuery;
    }
}

export function RefinementResults_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.RefinementResults", [], RefinementResults, () => [["ByQuery", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, array_type(string_type)])]]);
}

export function RefinementResults_get_Empty() {
    return new RefinementResults(ofSeq(empty(), {
        Compare: comparePrimitives,
    }));
}

export class RefinementParameters extends Record {
    constructor(MinSessions, MinRefinementRate, MinRefinedIr, MinIrRatio) {
        super();
        this.MinSessions = MinSessions;
        this.MinRefinementRate = MinRefinementRate;
        this.MinRefinedIr = MinRefinedIr;
        this.MinIrRatio = MinIrRatio;
    }
}

export function RefinementParameters_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.RefinementParameters", [], RefinementParameters, () => [["MinSessions", option_type(int32_type)], ["MinRefinementRate", option_type(float64_type)], ["MinRefinedIr", option_type(float64_type)], ["MinIrRatio", option_type(float64_type)]]);
}

export class PerDay extends Record {
    constructor(Date$, Count) {
        super();
        this.Date = Date$;
        this.Count = Count;
    }
}

export function PerDay_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Kpi.PerDay", [], PerDay, () => [["Date", class_type("System.DateTime")], ["Count", int64_type]]);
}

export function interactionTypeFromString(tryValue) {
    return parseEnum(enum_type("Loop54.Portal.Api.Model.Kpi.InteractionType", int32_type, [["All", 0], ["Click", 1], ["AddToCart", 2], ["Purchase", 3]]), tryValue, true);
}

export const defaultInteractionType = 1;

export const interactionTypeLabels = ofSeq([[0, "All"], [1, "Click"], [2, "Add To Cart"], [3, "Purchase"]], {
    Compare: comparePrimitives,
});

export function matchTypeFromString(tryValue) {
    return parseEnum(enum_type("Loop54.Portal.Api.Model.Kpi.MatchType", int32_type, [["Exact", 0], ["Contain", 1], ["NotContain", 2], ["StartWith", 3], ["EndWith", 4], ["Regex", 5]]), tryValue, true);
}

export function matchTypeToString(tryValue) {
    return getEnumName(enum_type("Loop54.Portal.Api.Model.Kpi.MatchType", int32_type, [["Exact", 0], ["Contain", 1], ["NotContain", 2], ["StartWith", 3], ["EndWith", 4], ["Regex", 5]]), tryValue);
}

export const defaultMatchType = 1;

export const matchTypeLabels = ofSeq([[0, "Exact"], [1, "Contains"], [2, "Doesn\'t Contain"], [3, "Starts With"], [4, "Ends With"], [5, "Regex"]], {
    Compare: comparePrimitives,
});

