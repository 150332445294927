import { reject, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { AccessDeniedException, UnauthorizedException, InternalServerErrorException, RateLimitedException } from "../Utils/ApiUtils.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { PortalServerErrorNotification } from "../Components/Notification/PortalServerErrorNotification.fs.js";
import * as react from "react";
import { SigninLink, signinSilent } from "../Services/Auth/Auth.fs.js";
import { empty } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { InvalidUserException_$ctor_Z721C83C5, InvalidUserException } from "../Services/Auth/User.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.18.0/String.js";
import { Context } from "../Services/Api/Api.fs.js";
import { Context as Context_1 } from "../Services/Notifications/Notifications.fs.js";

function apiCallExceptionHandler(api, notifications, call, ex) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr_1, pr;
        if (ex instanceof RateLimitedException) {
            notifications.Send("warning", "Too many requests", "You\'re sending too many requests, please try again in a while.");
            return reject(ex);
        }
        else if (ex instanceof InternalServerErrorException) {
            const ex_2 = ex;
            notifications.Send("warning", "Data error", ex_2.message, (exMsg) => PortalServerErrorNotification({
                portalError: JSON.parse(exMsg),
            }));
            return reject(ex_2);
        }
        else if (ex instanceof UnauthorizedException) {
            notifications.Send("info", "Verifying credentials", "This may take a few seconds longer than normal.", (msg) => react.createElement("p", {}, msg));
            return ((pr_1 = ((pr = signinSilent(), pr.then((user) => call(api, user)))), pr_1.then(void 0, ((ex_4) => {
                notifications.Send("warning", "Session expired", "You have been logged out.", (msg_1) => {
                    let children_4;
                    const children_6 = [react.createElement("p", {}, msg_1), (children_4 = [SigninLink(empty())], react.createElement("p", {}, ...children_4))];
                    return react.createElement(react.Fragment, {}, ...children_6);
                });
                return reject(ex_4);
            }))));
        }
        else if (ex instanceof InvalidUserException) {
            notifications.Send("warning", "Could not load user", "The current user could not be loaded. Please sign in again.", (msg_2) => {
                let children_9;
                const children_11 = [react.createElement("p", {}, msg_2), (children_9 = [SigninLink(empty())], react.createElement("p", {}, ...children_9))];
                return react.createElement(react.Fragment, {}, ...children_11);
            });
            return reject(ex);
        }
        else if (ex instanceof AccessDeniedException) {
            notifications.Send("warning", "Cannot access this view with the selected scope", "Please select a valid scope.", (msg_3) => react.createElement("p", {}, msg_3));
            return reject(ex);
        }
        else {
            const ex_7 = ex;
            notifications.Send("warning", "Failed to fetch data", toString(ex_7), (msg_4) => {
                let children_16, s_5, children_18;
                const children_20 = [react.createElement("p", {}, "Encountered error when requesting data. Please try reloading the page."), (children_16 = [(s_5 = "If this happens repeatedly, please contact your Loop54 Customer Success Manager and provide them with the details below.", s_5)], react.createElement("p", {}, ...children_16)), (children_18 = [toText(printf("Technical details: %s"))(msg_4)], react.createElement("p", {}, ...children_18))];
                return react.createElement(react.Fragment, {}, ...children_20);
            });
            return reject(ex_7);
        }
    }));
}

export function apiCall(api, notifications, getUser, call) {
    let exceptionHandler;
    const call_1 = call;
    exceptionHandler = ((ex) => apiCallExceptionHandler(api, notifications, call_1, ex));
    return () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => ((() => {
        try {
            return getUser();
        }
        catch (ex_1) {
            return reject(InvalidUserException_$ctor_Z721C83C5(ex_1.message));
        }
    })().then((_arg) => (call(api, _arg))))).catch((_arg_1) => (exceptionHandler(_arg_1))))));
}

/**
 * Returns a closure of a promise that is the result of the requested API call with additional error handling.
 */
export function useApiCall(getUser, call) {
    return apiCall(react.useContext(Context), react.useContext(Context_1), getUser, call);
}

