import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, class_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { utcNow } from "../fable_modules/fable-library-js.4.18.0/Date.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.18.0/Util.js";

export class PortalServerError extends Record {
    constructor(UserMessage, Timestamp, DebugDetails) {
        super();
        this.UserMessage = UserMessage;
        this.Timestamp = Timestamp;
        this.DebugDetails = DebugDetails;
    }
}

export function PortalServerError_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Errors.PortalServerError", [], PortalServerError, () => [["UserMessage", string_type], ["Timestamp", class_type("System.DateTime")], ["DebugDetails", string_type]]);
}

export function PortalServerError_get_FailedToParseError() {
    return new PortalServerError("Failed to read error details returned from the server", utcNow(), defaultOf());
}

