import { toString, Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { record_type, option_type, unit_type, class_type, obj_type, lambda_type, list_type, tuple_type, array_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { NavProps, NavLink, RouteProps, Route, Location, RouteProps_$reflection, Location_$reflection } from "../Interop/ReactRouter.fs.js";
import { IdNameTuple_get_Empty, ScopeInfo_$reflection } from "../Loop54.Portal.Api.Model/ScopeInfo.fs.js";
import * as react from "react";
import { UrlParamsStoreContext__Set, UrlParamsStoreContext__TryGet_Z721C83C5, Context } from "./Query.fs.js";
import { useLocation } from "react-router-dom";
import { collect, contains, map as map_2, item } from "../fable_modules/fable-library-js.4.18.0/Array.js";
import { value as value_6, map } from "../fable_modules/fable-library-js.4.18.0/Option.js";
import { singleton, empty, append, map as map_1, ofArray, tryFind } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { stringHash, equals, curry3, uncurry2 } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { ItemProps, Item } from "../Interop/Antd/Menu.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.18.0/String.js";
import { ScopePartValueModule_fromUrlParam, ScopePartValueModule_isConcrete } from "./ScopeComponentUtils.fs.js";

export class WithCurrentQueryParamsHook extends Record {
    constructor(currentLocationWithNewQueryParams, routeWithCurrentQueryParams, navComponentWithCurrentQueryParams, narrowScopeIfNeeded) {
        super();
        this.currentLocationWithNewQueryParams = currentLocationWithNewQueryParams;
        this.routeWithCurrentQueryParams = routeWithCurrentQueryParams;
        this.navComponentWithCurrentQueryParams = navComponentWithCurrentQueryParams;
        this.narrowScopeIfNeeded = narrowScopeIfNeeded;
    }
}

export function WithCurrentQueryParamsHook_$reflection() {
    return record_type("Loop54.Portal.WebUI.Hooks.WithQueryParams.WithCurrentQueryParamsHook", [], WithCurrentQueryParamsHook, () => [["currentLocationWithNewQueryParams", lambda_type(tuple_type(string_type, array_type(string_type)), lambda_type(list_type(tuple_type(string_type, string_type)), list_type(Location_$reflection())))], ["routeWithCurrentQueryParams", lambda_type(lambda_type(string_type, string_type), lambda_type(list_type(RouteProps_$reflection()), lambda_type(list_type(string_type), lambda_type(lambda_type(list_type(tuple_type(string_type, obj_type)), class_type("Fable.React.ReactElement")), list_type(class_type("Fable.React.ReactElement"))))))], ["navComponentWithCurrentQueryParams", lambda_type(lambda_type(string_type, string_type), lambda_type(tuple_type(string_type, class_type("Fable.React.ReactElement")), lambda_type(tuple_type(string_type, array_type(string_type)), class_type("Fable.React.ReactElement"))))], ["narrowScopeIfNeeded", lambda_type(lambda_type(unit_type, option_type(ScopeInfo_$reflection())), lambda_type(string_type, string_type))]]);
}

export function useWithCurrentQueryParams() {
    const ctxt = react.useContext(Context);
    const urlSearchParams = useLocation().search;
    const currentLocationWithNewQueryParams = (urlParams, tupledArg, newParams) => {
        let vCache;
        const permittedParams = tupledArg[1];
        const paramsObject = new URLSearchParams(urlParams);
        const paramsObject$0027 = new URLSearchParams("");
        for (let idx = 0; idx <= (permittedParams.length - 1); idx++) {
            const prm = item(idx, permittedParams);
            const newParamValue = map((tuple) => tuple[1], tryFind((tupledArg_1) => (tupledArg_1[0] === prm), newParams));
            const currUrlParamValue = paramsObject.get(prm);
            const cachedParamValue = UrlParamsStoreContext__TryGet_Z721C83C5(ctxt, prm);
            let paramValue$0027;
            if (newParamValue == null) {
                if (currUrlParamValue == null) {
                    paramValue$0027 = ((cachedParamValue == null) ? "" : ((vCache = cachedParamValue, vCache)));
                }
                else if (cachedParamValue != null) {
                    const vUrl_1 = currUrlParamValue;
                    paramValue$0027 = vUrl_1;
                }
                else {
                    const vUrl = currUrlParamValue;
                    UrlParamsStoreContext__Set(ctxt, prm, vUrl);
                    paramValue$0027 = vUrl;
                }
            }
            else {
                const vNew = newParamValue;
                paramValue$0027 = vNew;
            }
            paramsObject$0027.set(prm, paramValue$0027);
        }
        return ofArray([new Location(0, [tupledArg[0]]), new Location(1, [toString(paramsObject$0027)])]);
    };
    return new WithCurrentQueryParamsHook(uncurry2(curry3(currentLocationWithNewQueryParams)(urlSearchParams)), (getUrlParams, props, routes, render) => map_1((route_1) => Route(append(ofArray([new RouteProps(0, [route_1]), new RouteProps(1, [getUrlParams(urlSearchParams)]), new RouteProps(4, [render])]), props), empty()), routes), (getUrlParams_1, tupledArg_2, tupledArg_3) => {
        const route_2 = tupledArg_3[0];
        return Item(singleton(new ItemProps(route_2)), singleton(NavLink(new NavProps(currentLocationWithNewQueryParams(getUrlParams_1(urlSearchParams), [route_2, tupledArg_3[1]], empty())), ofArray([tupledArg_2[1], react.createElement("span", {}, tupledArg_2[0])]))));
    }, (findBestMatchForScope, urlParams_1) => {
        let array, array_1;
        if (isNullOrWhiteSpace(urlParams_1)) {
            return urlParams_1;
        }
        else {
            const paramsObject_1 = new URLSearchParams(urlParams_1);
            const customerId = paramsObject_1.get("customerId");
            const storefrontId = paramsObject_1.get("storefrontId");
            const languageCode = paramsObject_1.get("languageCode");
            if (((((customerId == null) ? true : (storefrontId == null)) ? true : !ScopePartValueModule_isConcrete(ScopePartValueModule_fromUrlParam(value_6(storefrontId)))) ? true : (languageCode == null)) ? true : !ScopePartValueModule_isConcrete(ScopePartValueModule_fromUrlParam(value_6(languageCode)))) {
                const bestMatch = findBestMatchForScope();
                if (bestMatch != null) {
                    const bestMatch_1 = bestMatch;
                    const paramsObject_2 = new URLSearchParams(urlSearchParams);
                    paramsObject_2.set("customerId", bestMatch_1.AllowedScope.Customer.Id);
                    UrlParamsStoreContext__Set(ctxt, "customerId", bestMatch_1.AllowedScope.Customer.Id);
                    const bestStorefrontId = equals(IdNameTuple_get_Empty(), bestMatch_1.AllowedScope.Storefront) ? (((storefrontId != null) && ((array = map_2((scopeComponent) => scopeComponent.Storefront.Id, bestMatch_1.AvailableScopeComponents), contains(value_6(storefrontId), array, {
                        Equals: (x, y) => (x === y),
                        GetHashCode: stringHash,
                    })))) ? value_6(storefrontId) : item(0, bestMatch_1.AvailableScopeComponents).Storefront.Id) : bestMatch_1.AllowedScope.Storefront.Id;
                    paramsObject_2.set("storefrontId", bestStorefrontId);
                    UrlParamsStoreContext__Set(ctxt, "storefrontId", bestStorefrontId);
                    const bestLanguageCode = isNullOrWhiteSpace(bestMatch_1.AllowedScope.LanguageCode) ? (((languageCode != null) && ((array_1 = collect((scopeComponent_1) => scopeComponent_1.AvailableLanguages, bestMatch_1.AvailableScopeComponents), contains(value_6(languageCode), array_1, {
                        Equals: (x_1, y_1) => (x_1 === y_1),
                        GetHashCode: stringHash,
                    })))) ? value_6(languageCode) : item(0, item(0, bestMatch_1.AvailableScopeComponents).AvailableLanguages)) : bestMatch_1.AllowedScope.LanguageCode;
                    paramsObject_2.set("languageCode", bestLanguageCode);
                    UrlParamsStoreContext__Set(ctxt, "languageCode", bestLanguageCode);
                    return toString(paramsObject_2);
                }
                else {
                    return urlParams_1;
                }
            }
            else {
                return urlSearchParams;
            }
        }
    });
}

