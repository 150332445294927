import { Record } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { obj_type, bool_type, record_type, lambda_type, unit_type, string_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { mapIndexed, fold } from "../../fable_modules/fable-library-js.4.18.0/List.js";
import { replace } from "../../fable_modules/fable-library-js.4.18.0/String.js";
import { useTranslation } from "react-i18next";

export class i18n extends Record {
    constructor(changeLanguage, language) {
        super();
        this.changeLanguage = changeLanguage;
        this.language = language;
    }
}

export function i18n_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.I18n.i18n", [], i18n, () => [["changeLanguage", lambda_type(string_type, unit_type)], ["language", string_type]]);
}

export class I18n extends Record {
    constructor(t, i18n) {
        super();
        this.t = t;
        this.i18n = i18n;
    }
}

export function I18n_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.I18n.I18n", [], I18n, () => [["t", lambda_type(string_type, string_type)], ["i18n", i18n_$reflection()]]);
}

/**
 * Translates a given key and replaces placeholders in the format `{{0}}`, `{{1}}`, etc., with the provided arguments.
 */
export function I18n__tWithArgs(this$, key, args) {
    return fold((acc, tupledArg) => replace(acc, tupledArg[0], tupledArg[1]), this$.t(key), mapIndexed((i, value) => [`{{${i}}}`, value], args));
}

export class initI18next extends Record {
    constructor(lng, debug, resources) {
        super();
        this.lng = lng;
        this.debug = debug;
        this.resources = resources;
    }
}

export function initI18next_$reflection() {
    return record_type("Loop54.Portal.WebUI.Interop.I18n.initI18next", [], initI18next, () => [["lng", string_type], ["debug", bool_type], ["resources", obj_type]]);
}

export function numberFormat(fraction, n) {
    const t = useTranslation();
    const formatter = new Intl.NumberFormat(t.i18n.language,{
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
    });
    return formatter.format(n);
}

