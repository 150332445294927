import { class_type } from "./fable_modules/fable-library-js.4.18.0/Reflection.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.18.0/Util.js";
import { ofArray, map, isEmpty, collect } from "./fable_modules/fable-library-js.4.18.0/List.js";
import { Helpers_nothing } from "./fable_modules/Fable.React.9.4.0/Fable.React.Helpers.fs.js";
import { Props, Menu, SubMenuProps, SubMenu } from "./Interop/Antd/Menu.fs.js";
import * as react from "react";
import Navigation from "../src/Navigation.css";

export class Page {
    constructor(route, navComponent) {
        this.route = route;
        this.navComponent = navComponent;
    }
    get Routes() {
        const this$ = this;
        return this$.route;
    }
    get NavComponent() {
        const this$ = this;
        return this$.navComponent;
    }
}

export function Page_$reflection() {
    return class_type("Loop54.Portal.WebUI.Navigation.Page", undefined, Page);
}

export function Page_$ctor_36DAE502(route, navComponent) {
    return new Page(route, navComponent);
}

export class PageWithoutNavComponent {
    constructor(route) {
        this.route = route;
    }
    get Routes() {
        const this$ = this;
        return this$.route;
    }
    get NavComponent() {
        return defaultOf();
    }
}

export function PageWithoutNavComponent_$reflection() {
    return class_type("Loop54.Portal.WebUI.Navigation.PageWithoutNavComponent", undefined, PageWithoutNavComponent);
}

export function PageWithoutNavComponent_$ctor_1FEFDAB5(route) {
    return new PageWithoutNavComponent(route);
}

export class PageGroup {
    constructor(key, name, icon, pages) {
        this.key = key;
        this.name = name;
        this.icon = icon;
        this.pages = pages;
    }
    get Routes() {
        const this$ = this;
        return collect((page) => page.Routes, this$.pages);
    }
    get NavComponent() {
        let children_2;
        const this$ = this;
        const matchValue = this$.pages;
        if (isEmpty(matchValue)) {
            return Helpers_nothing;
        }
        else {
            const elems = map((page) => page.NavComponent, matchValue);
            return SubMenu(ofArray([new SubMenuProps(0, [(children_2 = [this$.icon, react.createElement("span", {}, this$.name)], react.createElement(react.Fragment, {}, ...children_2))]), new SubMenuProps(1, [this$.key])]), elems);
        }
    }
}

export function PageGroup_$reflection() {
    return class_type("Loop54.Portal.WebUI.Navigation.PageGroup", undefined, PageGroup);
}

export function PageGroup_$ctor_Z104CF0BE(key, name, icon, pages) {
    return new PageGroup(key, name, icon, pages);
}

export function createRootNavComponent(pages, defaultOpenKeys, defaultSelectedKeys) {
    return Menu(ofArray([new Props(0, ["inline"]), new Props(3, [Navigation.menu]), new Props(1, [defaultOpenKeys]), new Props(2, [defaultSelectedKeys])]), map((page) => page.NavComponent, pages));
}

