import { getTicks, toString } from "../fable_modules/fable-library-js.4.18.0/Date.js";
import { append, ofArray, singleton } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { class_type, array_type, record_type, string_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { interpolate, toText, join } from "../fable_modules/fable-library-js.4.18.0/String.js";

function isoDate(dt) {
    return toString(dt, "yyyy-MM-dd");
}

export class QueryFilter extends Record {
    constructor(customerId, storefrontId, languageCode) {
        super();
        this.customerId = customerId;
        this.storefrontId = storefrontId;
        this.languageCode = languageCode;
    }
    get dependency() {
        const this$ = this;
        return `${this$.customerId}${this$.storefrontId}${this$.languageCode}`;
    }
    get toArgs() {
        const this$ = this;
        return ofArray([["customerId", singleton(this$.customerId)], ["storefrontId", singleton(this$.storefrontId)], ["languageCode", singleton(this$.languageCode)]]);
    }
}

export function QueryFilter_$reflection() {
    return record_type("Loop54.Portal.Api.Model.QueryFilter.QueryFilter", [], QueryFilter, () => [["customerId", string_type], ["storefrontId", string_type], ["languageCode", string_type]]);
}

export function QueryFilter_get_NotInitialized() {
    return new QueryFilter("", "", "");
}

export function QueryFilter__get_dependency(this$) {
    return this$.dependency;
}

export function QueryFilter__get_toArgs(this$) {
    return this$.toArgs;
}

export class KpiQueryFilter extends Record {
    constructor(customerId, storefrontId, languageCode, split, startDate, endDate) {
        super();
        this.customerId = customerId;
        this.storefrontId = storefrontId;
        this.languageCode = languageCode;
        this.split = split;
        this.startDate = startDate;
        this.endDate = endDate;
    }
    get dependency() {
        const this$ = this;
        const splits = join(",", this$.split);
        return toText(interpolate("%s%P()%s%P()%d%P()%d%P()", [QueryFilter__get_dependency(KpiQueryFilter__get_asQueryFilter(this$)), splits, getTicks(this$.startDate), getTicks(this$.endDate)]));
    }
    get toArgs() {
        const this$ = this;
        return append(QueryFilter__get_toArgs(KpiQueryFilter__get_asQueryFilter(this$)), ofArray([["split", ofArray(this$.split)], ["startDate", singleton(isoDate(this$.startDate))], ["endDate", singleton(isoDate(this$.endDate))]]));
    }
}

export function KpiQueryFilter_$reflection() {
    return record_type("Loop54.Portal.Api.Model.QueryFilter.KpiQueryFilter", [], KpiQueryFilter, () => [["customerId", string_type], ["storefrontId", string_type], ["languageCode", string_type], ["split", array_type(string_type)], ["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]]);
}

export function KpiQueryFilter__get_dependency(this$) {
    return this$.dependency;
}

export function KpiQueryFilter__get_toArgs(this$) {
    return this$.toArgs;
}

function KpiQueryFilter__get_asQueryFilter(this$) {
    return new QueryFilter(this$.customerId, this$.storefrontId, this$.languageCode);
}

