import 'boomerangjs';  // Should register a BOOMR property on the window object.
import 'boomerangjs/plugins/rt.js';  // Required to get navigation timings.
import 'boomerangjs/plugins/auto-xhr.js';  // Required by plugins.SPA.
import 'boomerangjs/plugins/spa.js';  // Required by plugins.History.
import 'boomerangjs/plugins/history.js';  // Used to infer soft navigations in React.

import config from 'config';

function initialiseBoomerang (user) {
    window.BOOMR.init({
        beacon_url: `${config.urls.api}/RealUserMonitoring/RegisterPageLoad`,
        beacon_type: 'POST',
        beacon_auth_token: `Bearer ${user.accessToken}`,
        site_domain: config.urls.webui,

        // Disable debug output in the browser JavaScript console, because it is VERY verbose. Comment out to enable.
        log: null,

        // This configuration sets Boomerang up for SPA usage, rather than traditional page loads.
        autorun: false,
        instrument_xhr: true,
        AutoXHR: { monitorFetch: true, },
        History: { auto: true, },
    });
}

export default initialiseBoomerang;
