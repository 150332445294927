import { Record } from "../fable_modules/fable-library-js.4.18.0/Types.js";
import { array_type, class_type, int32_type, record_type, float64_type } from "../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { int32ToString } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { Product_$reflection } from "./Product.fs.js";

export class PulseExtraKpis extends Record {
    constructor(PulseScore, Momentum, Popularity) {
        super();
        this.PulseScore = PulseScore;
        this.Momentum = Momentum;
        this.Popularity = Popularity;
    }
}

export function PulseExtraKpis_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Pulse.PulseExtraKpis", [], PulseExtraKpis, () => [["PulseScore", float64_type], ["Momentum", float64_type], ["Popularity", float64_type]]);
}

function RankHelper_getRankAsString(rank) {
    if (rank === -1) {
        return "?";
    }
    else if (rank > 1000000000) {
        return `${rank - 1000000000}+`;
    }
    else {
        return int32ToString(rank);
    }
}

function RankHelper_getRank(rank) {
    if (rank > 1000000000) {
        return (rank - 1000000000) | 0;
    }
    else {
        return rank | 0;
    }
}

export class PulseRankingHistory extends Record {
    constructor(Rank, DateUtc, ExtraKpis) {
        super();
        this.Rank = (Rank | 0);
        this.DateUtc = DateUtc;
        this.ExtraKpis = ExtraKpis;
    }
}

export function PulseRankingHistory_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Pulse.PulseRankingHistory", [], PulseRankingHistory, () => [["Rank", int32_type], ["DateUtc", class_type("System.DateTime")], ["ExtraKpis", PulseExtraKpis_$reflection()]]);
}

export function PulseRankingHistory__GetRank(this$) {
    return RankHelper_getRank(this$.Rank);
}

export class PinHistory extends Record {
    constructor(Rank, DateUtc) {
        super();
        this.Rank = (Rank | 0);
        this.DateUtc = DateUtc;
    }
}

export function PinHistory_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Pulse.PinHistory", [], PinHistory, () => [["Rank", int32_type], ["DateUtc", class_type("System.DateTime")]]);
}

export class RankingHistory extends Record {
    constructor(Product, Rank, KpisToday, History, PinHistory) {
        super();
        this.Product = Product;
        this.Rank = (Rank | 0);
        this.KpisToday = KpisToday;
        this.History = History;
        this.PinHistory = PinHistory;
    }
}

export function RankingHistory_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Pulse.RankingHistory", [], RankingHistory, () => [["Product", Product_$reflection()], ["Rank", int32_type], ["KpisToday", PulseExtraKpis_$reflection()], ["History", array_type(PulseRankingHistory_$reflection())], ["PinHistory", array_type(PinHistory_$reflection())]]);
}

export function RankingHistory__GetRank(this$) {
    return RankHelper_getRank(this$.Rank);
}

export function RankingHistory__GetRankAsString(this$) {
    return RankHelper_getRankAsString(this$.Rank);
}

export class PulseRankingsResponse extends Record {
    constructor(PulseRankings, TotalItems) {
        super();
        this.PulseRankings = PulseRankings;
        this.TotalItems = (TotalItems | 0);
    }
}

export function PulseRankingsResponse_$reflection() {
    return record_type("Loop54.Portal.Api.Model.Pulse.PulseRankingsResponse", [], PulseRankingsResponse, () => [["PulseRankings", array_type(RankingHistory_$reflection())], ["TotalItems", int32_type]]);
}

