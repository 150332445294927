// This file contains definitions of various feature flags to hide/show things in the portal based on both build-time conditions and, in the future,
// dynamic conditions. See HACKING.md for details.

// Build-time conditions are indicated through the use of the Webpack DefinePlugin in webpack.config.js, and should be re-exported here.
export const DiagnosticsEnabled = CONST_DIAGNOSTICS_ENABLED;

// React hook that returns a variable indicating whether or not the currently
// signed-in user has an email address where the hostname part matches at least one
// string in the provided array. Useful to feature-flag things that should only be available
// internally. Weird names, but hooks are supposed to start with the word "use".
// Needs to be a hook in order to get access to the state hook, which is used
// because it makes for a more convenient interface than the promise stuff.
import React, { useState, useEffect } from 'react';
import { getCurrentUser } from '../build/Services/Auth/Auth.fs.js';
function useAccountEmailHosts(hostnames) {
    const [accountEmailHasTargetHostname, setAccountEmailHasTargetHostname] = useState(false);

    useEffect(() => {
        getCurrentUser().then(user => {
            let someEndsWith = hostnames.some(hostname => user.profile.email.endsWith(`@${hostname}`));
            return setAccountEmailHasTargetHostname(someEndsWith);
        });
    });

    return accountEmailHasTargetHostname;
}

export function useExperimentalFeaturesEnabled() {
    return useAccountEmailHosts(['loop54.com','fact-finder.com']);
}
