import { createObj } from "../fable_modules/fable-library-js.4.18.0/Util.js";
import { singleton } from "../fable_modules/fable-library-js.4.18.0/List.js";
import { QueryParamProvider as QueryParamProvider_1, encodeQueryParams } from "use-query-params";
import { Route } from "react-router-dom";
import * as react from "react";

export function encodeQueryParam(key, paramType, v) {
    const paramMap = createObj(singleton([key, paramType]));
    const paramValues = createObj(singleton([key, v]));
    return encodeQueryParams(paramMap, paramValues)[key];
}

export function QueryParamProvider(children) {
    const props = {
        ReactRouterRoute: Route,
    };
    return react.createElement(QueryParamProvider_1, props, ...children);
}

