import { Union } from "../../fable_modules/fable-library-js.4.18.0/Types.js";
import { union_type, class_type, string_type, bool_type } from "../../fable_modules/fable-library-js.4.18.0/Reflection.js";
import { toProps } from "../../Interop/React.fs.js";
import * as react from "react";
import { Dropdown as Dropdown_1 } from "antd";

export class DropdownProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Arrow", "Label", "Overlay", "OverlayClassName", "Trigger", "Placement"];
    }
}

export function DropdownProps_$reflection() {
    return union_type("Loop54.Portal.WebUI.Components.Dropdown.DropdownProps", [], DropdownProps, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Dropdown(props, elems) {
    const props_1 = toProps(props);
    return react.createElement(Dropdown_1, props_1, ...elems);
}

